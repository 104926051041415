//选择仓库
import * as React from 'react';
import { Shadow } from '@/components';
import { Button, Table, Radio, Icon } from 'uiw';
import API from '@/utils/http';
import style from '../index.module.scss';

export default class WareHouseShadow extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            columns: [
                {
                    title: () => (
                        <React.Fragment>
                            {this.state.prevId.length > 0 ?
                                <Icon type="left" onClick={this.backRoot.bind(this)} />
                                :
                                <p></p>
                            }
                        </React.Fragment>
                    ),
                    width: 60,
                    key: 'checked',
                    render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => (
                        <div className={style.radioShadow}>
                            <div className={style.bg}></div>
                            <Radio name='unit' checked={rowData.checked}></Radio>
                        </div>
                    )
                },
                { title: '编号', width: 200, key: 'KUserCode' },
                {
                    title: '名称',
                    key: 'KFullName',
                    render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => (
                        <p className="overflow">{rowData.Soncount > 0 ? '...' : ''}{rowData.KFullName}</p>
                    )
                }
            ],
            dataSource: [],
            prevId: [],       //上一层查询标识数组
            choosed: {},
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isOpen==true) {
            this.getData();
        }
    }


    onClose = () => {
        this.props.onClose && this.props.onClose();
    }

    onConfirm = () => {
        let { choosed } = this.state;
        this.props.onConfirm && this.props.onConfirm(choosed);
    }

    getData = (id?: any) => {

       let multiDbId=this.props.multiDbId?this.props.multiDbId:"";

        let param = {
            mth: 'Ktype',
            parid: id || '',
            stypeid: this.props.stypeId,
            multiDbId:multiDbId
        }
        API.postData(param, '/UI/selectType/ajax.ashx', (res: any) => {
            this.setState({
                dataSource: res.data
            })
        })
    }

    //选择行
    chooseItem = (data: any) => {
        //有子集
        if (data.Soncount > 0) {
            let { prevId } = this.state;
            prevId.push(data.KtypeID);
            this.setState({
                prevId,
                choosed: {}
            }, () => {
                this.getData(data.KtypeID);
            })
        } else {
            this.setState({
                choosed: data
            })
        }
    }

    //回到上一层
    backRoot = async () => {
        let { prevId } = this.state;
        let len = prevId.length;
        await this.getData(prevId[len - 2]);
        prevId.splice(len - 1, 1);
        this.setState({
            prevId
        })
    }

    render() {
        let { isOpen } = this.props;
        let { columns, dataSource, choosed } = this.state;
        dataSource = dataSource.map((item: any, idx: number) => {
            const isChecked = choosed.KUserCode === item.KUserCode;
            return { checked: isChecked, ...item };
        });
        return (
            <Shadow
                title="选择仓库"
                isOpen={isOpen}
                height={400}
                onClose={this.onClose.bind(this)}
                footer={
                    <React.Fragment>
                        <Button type="primary" onClick={this.onConfirm.bind(this)}>选择</Button>
                    </React.Fragment>
                }
            >
                <Table
                    className="custom-table custom-table-hasline"
                    columns={columns}
                    data={dataSource}
                    onCell={(rowData) => {
                        this.chooseItem(rowData);
                    }}
                />
            </Shadow>
        )
    }
}