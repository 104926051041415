//设置商城商家对应关系
import * as React from 'react';
import { Shadow, Confirm, Alert } from '@/components';
import { Button, Table, Radio, Input, Pagination, Select } from 'uiw';
import API from '@/utils/http';

export default class TipsChangeShop extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            dataSource: [],
            selectedShopId: ""
        }
    }
    componentDidMount() {

    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isOpen == true) {
            this.getData();
        }
    }

    //关闭
    onClose = () => {
        this.props.onClose && this.props.onClose();
    }

    //确定
    onConfirm = () => {
        let that = this;
        let { shopId } = this.props;
        let { selectedShopId } = this.state;
        if (selectedShopId) {
            shopId = selectedShopId;
        }
        let param = {
            mth: "setshop",
            shop: shopId
        };
        API.postData(param, '/login.aspx', (res: any) => {
            if (res.code == 0) {
                window.location.href = "/";
                //window.location.href = "/ui/index.aspx";
            } else {
                Alert("切换失败");
            }
        });
    }

    //获取数据
    getData = () => {
        let param = {
            mth: 'GetShopList',
        }
        API.postData(param, '/UI/ChangeShop.aspx', (res: any) => {
            this.setState({
                dataSource: res
            })
        })
    }

    // 改变下拉框
    changeSelectSec = (e: any) => {
        let selectedShopId = e.target.value;
        this.setState({ selectedShopId: selectedShopId });
    }

    // 下拉框
    selectSec = (list: Array<any>, shopId: any) => {
        let selected = list?.find((i: any) => i.ID == shopId);
        return (
            <Select defaultValue={selected?.Key} onChange={this.changeSelectSec.bind(this)}>
                {list?.map((item: any, index: number) =>
                    <Select.Option key={item.ID} value={item.ID}>{item.EcshopName}</Select.Option>
                )}
            </Select>
        )
    }

    render() {
        let { isOpen, shopId } = this.props;
        let { columns, dataSource, page, searchForm } = this.state;
        return (
            <Shadow
                title="切换网店"
                isOpen={isOpen}
                width={400}
                onClose={this.onClose.bind(this)}
                footer={
                    <Button type="primary" onClick={this.onConfirm.bind(this)}>确定</Button>
                }
            >
                <div style={{padding:'10px 0'}}>
                    {this.selectSec(dataSource, shopId)}
                </div>
            </Shadow>
        )
    }
}