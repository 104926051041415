//loader框
import * as React from 'react';
import * as ReactDom from 'react-dom';


function LoadHelp() {
    //

}

LoadHelp.prototype = {


    box: null,

    show: function (callback) {

        let delay = function () {
            if (!window.helpList) {
                console.log('延迟执行');
                setTimeout(delay, 2000);
                return;
            }
            callback(window.helpList);
        };

        setTimeout(delay, 2000);
    }
}

export default LoadHelp;