import * as React from 'react';
import classnames from 'classnames';
import { Pagination, Input, Button, Select } from 'uiw';
import './index.scss';

//预设每页条数
const pagiCount = [5, 10, 15, 20, 50, 100];

export default class Pagi extends React.Component<any, any>{
    static defaultProps = {
        showCount: false     //是否显示切换每页条数
    };

    constructor(props: any) {
        super(props);
        this.state = {
            defaultPagiCount: props.pageSize ? props.pageSize : 15,
            goNum: 0,
            currentPagi: 1       //当前页数
        }
    }

    //改变每页条数
    changeCount = (e: any) => {
        let { total } = this.props;
        this.setState({
            defaultPagiCount: e.target.value,
            currentPagi: Number(1)
        }, () => {
            this.props.onChange && this.props.onChange(1, total, this.state.defaultPagiCount)
        })

    }

    //改变input值
    changeGoNum = (e: any) => {
        let val = e.target.value;
        let { defaultPagiCount } = this.state;
        let { total } = this.props;
        let maxNum = Math.ceil(total / defaultPagiCount);
        this.setState({
            goNum: val >= maxNum ? maxNum : val
        })
    }

    //go
    goPagi = () => {
        let { goNum, defaultPagiCount } = this.state;
        let { total } = this.props;
        let num = Number(goNum) < 1 ? 1 : Number(goNum);
        this.setState({
            currentPagi: num
        }, () => {
            this.props.onChange && this.props.onChange(num, total, defaultPagiCount);
        })
    }

    render() {
        let { showCount, total, ...other } = this.props;
        let { defaultPagiCount, currentPagi, goNum } = this.state;

        return (
            <div className="pagi-cont box box-center">
                <p className="text-gray totle-cont">共{Math.ceil(total / defaultPagiCount)}页，{total}条数据</p>
                {showCount &&
                    <div className="select-count box box-center">
                        <p>每页</p>
                        <Select size="small" defaultValue={defaultPagiCount} onChange={this.changeCount.bind(this)}>
                            {pagiCount.map((item: any) =>
                                <Select.Option key={item} value={item}>{item}</Select.Option>
                            )}
                        </Select>
                        <p>条</p>
                    </div>
                }
                <Pagination
                    current={currentPagi}
                    pageSize={defaultPagiCount}
                    total={total}
                    {...other}
                />
                <div className="go-pagi box box-center">
                    <p>跳转至</p>
                    <Input size="small" value={goNum == 0 ? '' : goNum} onChange={this.changeGoNum.bind(this)} />
                    <p>页</p>
                    <Button className="go-btn" type="light" size="small" onClick={this.goPagi.bind(this)}>GO</Button>
                </div>
            </div>
        )
    }
}