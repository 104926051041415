//tab
import * as React from 'react';
import './index.scss';

export default class Tab extends React.Component<any, any>{
    changeTab = (n: number) => {
        this.props.changeTab && this.props.changeTab(n);
    }
    render() {
        let { list, tabIndex, discrib,ZT } = this.props;
        return (
            <div className="page-tab box box-center">
                {/*显示账套选项*/}
                {ZT &&
                  ZT()
                }
                <ul className="box1 box box-center">
                    {list.map((item: any, index: number) =>
                        <li key={index}
                            className={index === tabIndex ? 'active' : ''}
                            onClick={this.changeTab.bind(this, index)}
                        >{item}</li>
                    )}
                </ul>
                {(discrib && discrib !== '') &&
                    <p className="discrib">{discrib}</p>
                }
            </div>
        )
    }
}