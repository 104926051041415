//框架头部
import TipsChangeShop from '@/widget/TipsChangeShop';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Tooltip } from 'uiw';
import './index.scss';

class IframeHeader extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            tipsChangeShopShow: false
        }
    }

    //切换菜单
    handleChange = (link: string, n: number) => {
        this.props.history.push(link);
        this.props.handleChangeMenu && this.props.handleChangeMenu(n);
    }

    //删除菜单
    handleDelete = (event: any, n: number) => {
        let { menuList } = this.props;
        event.stopPropagation();  //阻止事件冒泡
        if (menuList[n].active) {    //如果当前删除菜单为选中状态
            if (menuList.length === 1 && n === 0) {    //如果是最后一个菜单
                this.props.history.push('/');
            } else if (n === menuList.length - 1) {     //如果是排列在最后的菜单
                this.handleChange(menuList[n - 1].link, n - 1);
            } else {
                this.handleChange(menuList[menuList.length - 1].link, menuList.length - 1);
            }
        }
        this.props.handleDelete && this.props.handleDelete(n);
    }

    // 切换网店
    handleChangeShop = () => {
        this.setState({ tipsChangeShopShow: true });
    }

    //退出
    handleSignOut = () => {
        //his.props.handleSignOut && this.props.handleSignOut();
        window.location.href="/logout.aspx";
    }

    render() {
        let { tipsChangeShopShow } = this.state;
        const { menuList, user, shopId } = this.props;
        return (
            <div className="page-header">
                <ul className="header-tab">
                    {
                        (menuList || []).map((item: any, index: number) =>
                            <li key={index} className={item.active ? 'active' : ''} onClick={() => this.handleChange(item.link, index)}>
                                <p>{item.name}</p>
                                <i className="shut" onClick={(e) => this.handleDelete(e, index)}>×</i>
                            </li>
                        )
                    }
                </ul>
                {/* 头部 */}
                <div className="header-act box box-center">
                    <p>欢迎您，{user}！</p>
                    <ul className="act-list box box-center">
                        <li onClick={this.handleChangeShop.bind(this)}>切换网店</li>
                        <li onClick={this.handleSignOut}>退出</li>
                        <li><a href='https://www.kancloud.cn/rwxkj/qct/505608' target="_blank">帮助</a></li>
                        {/* <li>
                            <Tooltip content="切换到经典版" placement="left">
                                <Icon type="swap" color="#fff" className="switch-icon" onClick={() => { window.location.href = "/UI/index.aspx"; }} />
                            </Tooltip>
                        </li> */}
                    </ul>
                </div>
                {/* 切换网店弹层 */}
                <TipsChangeShop
                    isOpen={tipsChangeShopShow}
                    shopId={shopId}
                    onClose={() => this.setState({ tipsChangeShopShow: false })}
                    onConfirm={(choosed: any) => {
                        this.setState({ tipsChangeShopShow: false });
                    }}
                />
            </div>

        )
    }
}
export default withRouter(IframeHeader);   //withRouter：使用父级history