import * as React from 'react';
import loadSprite from './loadSprite';
import './index.scss';

export interface SvgIconProps {
  /**
   * icon的type
   */
  type: string;
  size?: string;
  color?: string;
  onClick?: () => void;
}

export default class SvgIcon extends React.PureComponent<SvgIconProps, any> {
  static defaultProps = {
    size: '20',
  };
  
  componentDidMount() {
    loadSprite();
  }

  render() {
    const { type, size, ...restProps} = this.props;
    const style = {
      width:size+'px',
      height:size+'px',
      backgroundSize: 'cover',
    };
    return (
      <svg className='icon' style={style} {...restProps}>
        <use xlinkHref={`#${type}`} />
      </svg>
    );
  }
}
