//loader框
import * as React from 'react';
import * as ReactDom from 'react-dom';
import { Loader } from 'uiw';

import './loading.scss';


function Loading() {
    //



}

Loading.prototype = {

    id: "qct_loading_box",

    box: null,

    fullscreen: true,

    show: function () {

        var _this = this;

        var isExists = document.getElementById(_this.id);
        if (isExists) {
            _this.box = isExists;
            isExists.style.display = "block";
            return;
        }

        let box = document.createElement('div');
        box.id = _this.id;
        //

        document.body.appendChild(box);
        _this.box = box;
        ReactDom.render(
            <div className="qct_loading_box_ctx">
                <div className="qct_loading_box_bg"></div>
                <Loader
                    tip="数据处理中..."
                    size="large"
                    fullscreen={_this.fullscreen}
                />
            </div>
            ,
            this.box
        );

    },

    close: function () {

        let div = this.box;

        if (div) {
            div.style.display = "none";
        }
    }

}

export default Loading;