//设置商城商家对应关系
import * as React from 'react';
import { Shadow, Confirm, Alert, Pagi } from '@/components';
import { Button, Table, Radio, Input, Pagination } from 'uiw';
import API from '@/utils/http';
import style from '../index.module.scss';

export default class EcShopPayType extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            choosed: {},
            columns: [
                {
                    title: '',
                    width: 60,
                    key: 'checked',
                    render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => (
                        <div className={style.radioShadow}>
                            <div className={style.bg}></div>
                            <Radio name='unit' checked={rowData.checked}></Radio>
                        </div>
                    )
                },
                { title: '支付方式编号', key: 'payMannerCode' },
                { title: '支付方式名称', key: 'payMannerName' },
            ],
            dataSource: [],
            page: {
                pageIndex: 1,
                pageSize: 10,
                count: 0
            },
            searchForm: {
                keyword: '',
            }
        }
    }
    componentDidMount() {

    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isOpen == true) {
            let { searchForm } = this.state;
            this.getData(1, searchForm);
        }
    }

    //关闭
    onClose = () => {
        this.clearData();
        this.props.onClose && this.props.onClose();
    }
    //确定
    onConfirm = () => {
        this.CheckChoosed();
    }

    // 检查是否存在对应关系
    CheckChoosed = () => {
        let that = this;
        let { choosed } = this.state;

        if (!choosed.payMannerID) {
            Alert("请选择一个支付方式进行设置!");
            return;
        }

        this.SetRelations();

    }

    // 设置关系
    SetRelations = () => {
        let that = this;
        let { choosed } = that.state;//当前列表选择
        let { selectedObj } = that.props;//父列表选择

        let param = {
            mth: "SetAtypeRelations",
            EcShopAID: choosed.payMannerID,
            EcShopAName: choosed.payMannerName,
            EcShopANo: choosed.payMannerCode,
            aFullname: selectedObj.aFullname,
            aUserCode:selectedObj.aUserCode,
            aRec: selectedObj.aRec
        };
        API.postData(param, '/UI/abill/ajax.ashx', (res: any) => {
            if (res.data) {
                Alert("设置成功！");
                that.clearData();
                that.props.onConfirm && that.props.onConfirm(true);
            }
            else {
                Alert("后台服务出现错误！请联系系统提供方!");
            }
        });

    }

    // 清除选择记忆
    clearData = () => {
        this.setState((preState: any) => {
            let searchForm = preState.searchForm;
            searchForm.keyword = '';
            return { searchForm: searchForm, choosed: {} };
        })
    }

    //获取数据
    getData = (n: number, form: any) => {
        let param = {
            mth: 'getshopPayType',
            EcShopSkuNo: form.keyword,
            pageCurrent: n
        }
        API.postData(param, '/UI/abill/ajax.ashx', (res: any) => {
            let obj: any = {};
            obj.pageIndex = n;
            obj.pageSize = res.pageSize;
            obj.count = res.rowCount;
            this.setState({
                dataSource: res.data,
                page: obj
            })
        })
    }

    //改变表单
    changeForm = (name: string, e: any) => {
        let obj = this.state.searchForm;
        obj[name] = e.target.value;
        this.setState({
            searchForm: obj
        })
    }

    render() {
        let { isOpen, selectedObj } = this.props;
        let { columns, dataSource, page, searchForm, choosed } = this.state;
        
        dataSource = dataSource.map((item: any, idx: number) => {
            const isChecked = choosed.payMannerID === item.payMannerID;
            return { checked: isChecked, ...item };
        });

        let discrib = "（ERP收款账户编号：" + selectedObj.aUserCode + " ERP收款账户名称：" + selectedObj.aFullname + "）";
        return (
            <Shadow
                title="选择支付方式"   
                discrib={discrib}
                isOpen={isOpen}
                width={800}
                onClose={this.onClose.bind(this)}
                footer={
                    <Button type="primary" onClick={this.onConfirm.bind(this)}>确定</Button>
                }
            >
                <div className="shadow-search box box-center">
                    <div className="box box-center">
                        <p>支付方式</p>
                    </div>
                </div>
                <div className="martop" style={{ height: 400, overflow: 'auto' }}>
                    <Table
                        className="custom-table custom-table-hasline"
                        columns={columns}
                        data={dataSource}
                        onCell={(rowData) => {
                            this.setState({
                                choosed: rowData
                            })
                        }}
                    />
                </div>
            </Shadow>
        )
    }
}