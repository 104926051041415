//选择往来单位
import * as React from 'react';
import { Shadow, Pagi } from '@/components';
import { Button, Table, Radio, Icon } from 'uiw';
import API from '@/utils/http';
import style from '../index.module.scss';

export default class CustomerShadow extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            columns: [
                {
                    title: () => (
                        <React.Fragment>
                            {this.state.prevId.length > 0 ?
                                <Icon type="left" onClick={this.backRoot.bind(this)} />
                                :
                                <p></p>
                            }
                        </React.Fragment>
                    ),
                    width: 60,
                    key: 'checked',
                    render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => (
                        <div className={style.radioShadow}>
                            <div className={style.bg}></div>
                            <Radio name='customer' checked={rowData.checked}></Radio>
                        </div>
                    )
                },
                { title: '编号', width: 200, key: 'BUserCode' },
                {
                    title: '名称',
                    key: 'BFullName',
                    render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => (
                        <p className="overflow">{rowData.Soncount > 0 ? '...' : ''}{rowData.BFullName}</p>
                    )
                }
            ],
            dataSource: [],
            prevId: [],       //上一层查询标识数组
            choosed: {},
            page: {
                pageIndex: 1,
                pageSize: 10,
                count: 0
            },
        }
    }
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isOpen == true) {
            this.getData();
        }
    }

    onClose = () => {
        this.props.onClose && this.props.onClose();
    }

    onConfirm = () => {
        let { choosed } = this.state;
        this.props.onConfirm && this.props.onConfirm(choosed);
    }

    getData = (id?: any) => {
        let page = this.state.page;
        let { isCustomer,multiDbId } = this.props;
        
        let param = {
            mth: 'Btype',
            parid: id || '',
            pageIndex: page.pageIndex,
            isCustomer: isCustomer,
            multiDbId:multiDbId
        }
        API.postData(param, '/UI/selectType/ajax.ashx', (res: any) => {
            let data = res.data;
            page.count = data.rowCount;
            page.pageSize = data.pageSize;
            this.setState({
                dataSource: data.data,
                page: page
            })
        })
    }

    //选择行
    chooseItem = (data: any) => {
        //有子集
        if (data.Soncount > 0) {
            let { prevId } = this.state;
            prevId.push(data.BtypeID);
            this.setState({
                prevId,
                choosed: {}
            }, () => {
                this.getData(data.BtypeID);
            })
        } else {
            this.setState({
                choosed: data
            })
        }
    }

    //回到上一层
    backRoot = async () => {
        let { prevId } = this.state;
        let len = prevId.length;
        await this.getData(prevId[len - 2]);
        prevId.splice(len - 1, 1);
        this.setState({
            prevId
        })
    }

    render() {
        let { isOpen } = this.props;
        let { columns, dataSource, choosed, page } = this.state;
        dataSource = dataSource.map((item: any, idx: number) => {
            const isChecked = choosed.Brec === item.Brec;
            return { checked: isChecked, ...item };
        });
        return (
            <Shadow
                title="选择往来单位"
                isOpen={isOpen}
                height={400}
                onClose={this.onClose.bind(this)}
                footer={
                    <React.Fragment>

                        <Pagi alignment="right"
                            current={page.pageIndex}
                            pageSize={page.pageSize}
                            showCount={false}
                            total={page.count}
                            onChange={(current: number, total: number, pageSize: number) => {
                                let that = this;
                                page.pageIndex = current;
                                that.setState({
                                    page: page
                                }, function () {
                                    that.getData(current);
                                })
                            }} />
                        <div className=" martop">
                            <Button type="primary" onClick={this.onConfirm.bind(this)}>选择</Button>
                        </div>
                    </React.Fragment>
                }
            >
                <Table
                    className="custom-table custom-table-hasline"
                    columns={columns}
                    data={dataSource}
                    onCell={(rowData) => {
                        this.chooseItem(rowData);
                    }}
                />

            </Shadow>
        )
    }
}