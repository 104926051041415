//登录
import * as React from 'react';
import { Form, Input, Button, Select } from 'uiw'
import { Confirm, Alert } from '@/components';
import './index.scss';
import logo from '@/assets/images/qct-logo.png';
import API from '../../../utils/http.js';
import { isNullOrUndefined } from 'util';

export default class Login extends React.Component<any, any>{


    constructor(props: any) {
        super(props);

        this.state = {
            stepIndex: 0,
            userAccount: "",
            password: "",
            shopList: [],
            selectShopId: 0,
        };



    }


    login = (userAccount: string, psw: string) => {

        let that = this;

        API.postData({ mth: "login", username: userAccount, psw: psw }, "/login.aspx",
            function (res: any) {
                switch (res.code) {
                    case 0:
                        if (res.shop.length > 1) {
                            that.setState({ stepIndex: 1, shopList: res.shop, selectShopId: res.shop[0].ID, userAccount: userAccount });
                            return;
                        }
                        that.SetShop(res.shop[0].ID);
                        break;
                    case 10000:
                        Alert("该用户已被禁用！请联系客服进行处理");
                        break;
                    default:
                        Alert("用户名或密码错误!请核实登录账号信息");
                        break;
                }


            });

    }

    SetShop = (shopId: any) => {
        let that = this;
        API.postData({ mth: "setshop", shop: shopId }, "/login.aspx", function (res: any) {
            
            switch (res.code) {
                case 0:
                    console.log(res.link);
                    if (res.link != "/") {
                        window.location.href = res.link;
                    } else {
                        that.props.history.replace('/main/home');
                    }
                    break;
                case 1:
                    Alert("网店不存在");
                    break;
                case 2:
                    Alert("没有网店权限，请联系客服人员设置网店权限");
                    break;
                case 10001:
                    Alert("用户名或者密码错误！请确认后在登录");
                    break;
                default:
                    if (res.msg) {
                        Alert(res.msg);
                    } else {
                        Alert('获取网店信息失败！请重新登录');
                    }
                    break;
            }

        });

    }



    changeSelectSec = (e: any) => {
        let id = e.target.value;
        this.setState({ selectShopId: id });
    }

    onSubmit = () => {
        let that = this;
        let { selectShopId } = this.state;
        this.SetShop(selectShopId);
    }

    render() {
        let { stepIndex, shopList } = this.state;

       let  fields=[{
            userAccount: {
                labelFor: 'userAccount',
                children: <Input className="input-kuang" preIcon="user" type="text" placeholder="请输入账号" value={this.state.userAccount} />
            },
            userPsd: {
                labelFor: 'userPsd',
                children: <Input className="input-kuang" preIcon="lock" type="password" placeholder="请输入密码" value={this.state.password} />
            }
        }];
        return (
            <div className="login-container">
                <div className="left">
                </div>
                <div className="right">
                    <div className="cont">
                        <img className="logo" src={logo} alt="" />
                        {stepIndex == 0 ?
                            <Form
                                onSubmit={({ initial, current }) => {
                                    console.log('-->>', initial, current);
                                    this.login(current.userAccount, current.userPsd);
                                    //this.props.history.replace('/main');
                                    return null;
                                }}
                               fields={{
                                userAccount: {
                                    labelFor: 'userAccount',
                                    children: <Input className="input-kuang" preIcon="user" type="text" placeholder="请输入账号" value={this.state.userAccount} />
                                },
                                userPsd: {
                                    labelFor: 'userPsd',
                                    children: <Input className="input-kuang" preIcon="lock" type="password" placeholder="请输入密码" value={this.state.password} />
                                }
                            }}
                            >
                                {({ fields, state }) => {
                                    console.log('fields:', state);
                                    return (
                                        <div>
                                            {fields&&fields.userAccount}
                                            {fields&&fields.userPsd}
                                            <Button block size="large" className="login-btn" type="success" htmlType="submit" >登录</Button>
                                        </div>
                                    )
                                }}
                            </Form>
                            :
                            <div>
                                <Select className="input-kuang" defaultValue={shopList[0].ID} onChange={this.changeSelectSec.bind(this)}>
                                    {shopList.map((item: any, index: number) =>
                                        <Select.Option key={item.ID} value={item.ID}>{item.EcshopName}</Select.Option>
                                    )}
                                </Select>
                                <Button block size="large" className="login-btn" type="success" onClick={this.onSubmit.bind(this)}>确定</Button>
                            </div>
                        }
                    </div>
                    <p className="copyright">管家婆®出品</p>
                </div>
            </div>
        )
    }
}