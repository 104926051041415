
import lazyComponent from '@/common/lazyComponent';

export default [
    {
        path: '/',
        exact: true,
        component: lazyComponent(() => import('./home'))
    },
    {
        path: '/main/home',
        exact: true,
        component: lazyComponent(() => import('./home'))
    }, {
        path: '/main/setup/common',
        exact: true,
        component: lazyComponent(() => import('./setup/common'))
    },

    {
        path: '/main/setup/data',
        exact: true,
        component: lazyComponent(() => import('./setup/data'))
    },
    {
        path: '/main/setup/qfdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/QFSetUpData'))
    },
    {
        path: '/main/setup/fxdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/FxErpSetUpData'))
    },
    {
        path: '/main/setup/hhdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/HHSetUpData'))
    },
    {
        path: '/main/setup/Kingdeedata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/KingdeeSetUpData'))
    },
    {
        path: '/main/setup/YYdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/YYSetUpData'))
    },
    {
        path: '/main/setup/YYTPlusdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/YYTPlusSetUpData'))
    },
    {
        path: '/main/setup/YYU8data',
        exact: true,
        component: lazyComponent(() => import('./setup/data/YYU8SetUpData'))
    },
    {
        path: '/main/setup/Fzdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/FzSetUpData'))
    },
    {
        path: '/main/setup/GMdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/GMSetUpData'))
    },
    {
        path: '/main/setup/CMdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/CMSetUpData'))
    },
    {
        path: '/main/setup/ZYJSdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/ZYJSSetUpData'))
    },
    {
        path: '/main/setup/SPdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/SPSetUpData'))
    },
    {
        path: '/main/setup/HYSWdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/HYSWSetUpData'))
    },
    {
        path: '/main/setup/JieKedata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/JieKeSetUpData'))
    },
    {
        path: '/main/setup/QPdata',
        exact: true,
        component: lazyComponent(() => import('./setup/data/QPSetUpData'))
    },

    {
        path: '/main/setup/bill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill'))
    },
    {
        path: '/main/setup/HHbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/HHSetUpBill'))
    },
    {
        path: '/main/setup/spbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/SPSetUpBill'))
    },
    {
        path: '/main/setup/KingdeeSetUpBill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/KingdeeSetUpBill'))
    },
    {
        path: '/main/setup/A8bill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/A8SetUpBill'))
    },
    {
        path: '/main/setup/V3bill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/V3SetUpBill'))
    },
    {
        path: '/main/setup/V1bill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/V1SetUpBill'))
    },
    {
        path: '/main/setup/D9bill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/D9SetUpBill'))
    },
    {
        path: '/main/setup/CMbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/CMSetUpBill'))
    },
    {
        path: '/main/setup/GMbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/GMSetUpBill'))
    },

    {
        path: '/main/setup/QFbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/QFSetUpBill'))
    },
    {
        path: '/main/setup/YYbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/YYSetUpBill'))
    },
    {
        path: '/main/setup/YYTPlusbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/YYTPlusbill'))
    },
    {
        path: '/main/setup/YYU8Bill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/YYU8Bill'))
    },
    {
        path: '/main/setup/S3bill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/S3SetUpBill'))
    },
    {
        path: '/main/setup/FZbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/FZSetUpbill'))
    },
    {
        path: '/main/setup/ZYJSbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/ZYJSSetUpbill'))
    },
    {
        path: '/main/setup/HYSWbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/HYSWSetUpbill'))
    },
    {
        path: '/main/setup/JieKebill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/JieKeSetUpBill'))
    },
    {
        path: '/main/setup/Qpbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/QpSetUpBill'))
    },
    {
        path: '/main/setup/Qp1Sbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/Qp1SSetUpBill'))
    },
    {
        path: '/main/setup/Qp2Sbill',
        exact: true,
        component: lazyComponent(() => import('./setup/bill/Qp2SSetUpBill'))
    },
    {
        path: '/main/setup/price',
        exact: true,
        component: lazyComponent(() => import('./setup/price'))
    },
    {
        path: '/main/setup/hhprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/HHSetUpPrice'))
    },
    {
        path: '/main/setup/spprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/SPSetUpPrice'))
    },
    {
        path: '/main/setup/a8price',
        exact: true,
        component: lazyComponent(() => import('./setup/price/A8SetUpPrice'))
    },
    {
        path: '/main/setup/fxErpPrice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/FxErpSetUpPrice'))
    },
    {
        path: '/main/setup/cmprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/CMSetUpPrice'))
    },
    {
        path: '/main/setup/gmprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/GMSetUpPrice'))
    },
    {
        path: '/main/setup/yyprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/YYSetUpPrice'))
    },
    {
        path: '/main/setup/YYU8price',
        exact: true,
        component: lazyComponent(() => import('./setup/price/YYU8SetUpPrice'))
    },
    {
        path: '/main/setup/fzprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/FZSetUpPrice'))
    },
    {
        path: '/main/setup/zyjsprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/ZYJSSetUpPrice'))
    },
    {
        path: '/main/setup/Jiekeprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/JieKeSetUpPrice'))
    }, {
        path: '/main/setup/qpprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/QpSetUpPrice'))
    }, {
        path: '/main/setup/qp1sprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/QpSetUpPrice'))
    }, {
        path: '/main/setup/qp2sprice',
        exact: true,
        component: lazyComponent(() => import('./setup/price/QpSetUpPrice'))
    },
    {
        path: '/main/setup/oplog',
        exact: true,
        component: lazyComponent(() => import('./setup/oplog'))
    },
    {
        path: '/main/bill/order',
        exact: true,
        component: lazyComponent(() => import('./bill/order'))
    },
    {
        path: '/main/bill/order/:tabIndex',
        exact: true,
        component: lazyComponent(() => import('./bill/order'))
    },
    {
        path: '/main/bill/returnOrder',
        exact: true,
        component: lazyComponent(() => import('./bill/returnOrder'))
    },
    {
        path: '/main/bill/ABill',
        exact: true,
        component: lazyComponent(() => import('./bill/ABill'))
    },
    {
        path: '/main/bill/buyBill',
        exact: true,
        component: lazyComponent(() => import('./bill/buyBill'))
    },
    {
        path: '/main/base/customer',
        exact: true,
        component: lazyComponent(() => import('./base/customer'))
    },
    {
        path: '/main/base/customerscope',
        exact: true,
        component: lazyComponent(() => import('./base/customerScope'))
    },
    {
        path: '/main/base/customer/:tabIndex',
        exact: true,
        component: lazyComponent(() => import('./base/customer'))
    },
    {
        path: '/main/base/supplier',
        exact: true,
        component: lazyComponent(() => import('./base/supplier'))
    },
    {
        path: '/main/base/employee',
        exact: true,
        component: lazyComponent(() => import('./base/employee'))
    },
    {
        path: '/main/base/employeeSaleman',
        exact: true,
        component: lazyComponent(() => import('./base/employeeSaleman'))
    },
    {
        path: '/main/base/product',
        exact: true,
        component: lazyComponent(() => import('./base/product'))
    },
    {
        path: '/main/base/ecshopproduct',
        exact: true,
        component: lazyComponent(() => import('./base/ecshopproduct'))
    },
    {
        path: '/main/base/productCatalog',
        exact: true,
        component: lazyComponent(() => import('./base/productCatalog'))
    },
    {
        path: '/main/base/productsp',
        exact: true,
        component: lazyComponent(() => import('./base/productsp'))
    },
    {
        path: '/main/base/product/:tabIndex',
        exact: true,
        component: lazyComponent(() => import('./base/product'))
    },
    {
        path: '/main/base/FZproduct',
        exact: true,
        component: lazyComponent(() => import('./base/product/FZproduct'))
    },
    {
        path: '/main/base/productattr',
        exact: true,
        component: lazyComponent(() => import('./base/productattr'))
    },
    {
        path: '/main/setup/shop',
        exact: true,
        component: lazyComponent(() => import('./setup/shop'))
    }, {
        path: '/main/wms/set',
        exact: true,
        component: lazyComponent(() => import('./wms/set'))
    },
    {
        path: '/main/base/store',
        exact: true,
        component: lazyComponent(() => import('./base/store'))
    },
    {
        path: '/main/base/stock',
        exact: true,
        component: lazyComponent(() => import('./base/stock'))
    },
    {
        path: '/main/price/normalprice',
        exact: true,
        component: lazyComponent(() => import('./price/normalPrice'))
    },
    {
        path: '/main/price/normalprice/:tabIndex',
        exact: true,
        component: lazyComponent(() => import('./price/normalPrice'))
    },
    {
        path: '/main/price/preprice',
        exact: true,
        component: lazyComponent(() => import('./price/prePrice'))
    },
    {
        path: '/main/price/preprice/:tabIndex',
        exact: true,
        component: lazyComponent(() => import('./price/prePrice'))
    },
    {
        path: '/main/price/traceprice',
        exact: true,
        component: lazyComponent(() => import('./price/tracePrice'))
    },
    {
        path: '/main/price/traceprice/:tabIndex',
        exact: true,
        component: lazyComponent(() => import('./price/tracePrice'))
    },
    {
        path: '/main/base/spec/main',
        exact: true,
        component: lazyComponent(() => import('./base/spec/main'))
    },
    {
        path: '/main/base/spec/upload',
        exact: true,
        component: lazyComponent(() => import('./base/spec/upload'))
    },
    {
        path: '/main/base/spec/upload/:groupId',
        exact: true,
        component: lazyComponent(() => import('./base/spec/upload'))
    },
    {
        path: '/main/setup/MsgSeting',
        exact: true,
        component: lazyComponent(() => import('./setup/msg/MsgSeting'))
    },
    {
        path: '/main/base/ecshopcustomer',
        exact: true,
        component: lazyComponent(() => import('./base/ecshopcustomer'))
    },


    //多账套


    {
        path: '/main/setup/multidb',
        exact: true,
        component: lazyComponent(() => import('./multi/setup/multidb'))
    },
    {
        path: '/multi/base/product',
        exact: true,
        component: lazyComponent(() => import('./multi/base/product'))
    },

    {
        path: '/multi/base/customer',
        exact: true,
        component: lazyComponent(() => import('./multi/base/customer'))
    },
    {
        path: '/multi/base/employee',
        exact: true,
        component: lazyComponent(() => import('./multi/base/employee'))
    },
    {
        path: '/multi/base/store',
        exact: true,
        component: lazyComponent(() => import('./multi/base/store'))
    },
    {
        path: '/multi/base/stock',
        exact: true,
        component: lazyComponent(() => import('./multi/base/stock'))
    },
    {
        path: '/multi/bill/order',
        exact: true,
        component: lazyComponent(() => import('./multi/bill/order'))
    },
    {
        path: '/multi/bill/order/:tabIndex',
        exact: true,
        component: lazyComponent(() => import('./multi/bill/order'))
    },
    {
        path: '/multi/bill/returnOrder',
        exact: true,
        component: lazyComponent(() => import('./multi/bill/returnOrder'))
    },
    {
        path: '/multi/price/normalprice',
        exact: true,
        component: lazyComponent(() => import('./multi/price/normalPrice'))
    },
    {
        path: '/multi/setup/hhdata',
        exact: true,
        component: lazyComponent(() => import('./multi/setup/data/HHSetUpData'))
    },
    {
        path: '/multi/setup/BillSplitSetting',
        exact: true,
        component: lazyComponent(() => import('./multi/setup/BillSplitSetting'))
    },
    {
        path: '/multi/setup/HHBill',
        exact: true,
        component: lazyComponent(() => import('./multi/setup/bill/HHSetUpBill'))
    },
    {
        path: '/multi/setup/hhprice',
        exact: true,
        component: lazyComponent(() => import('./multi/setup/price/HHSetUpPrice'))
    },
    //多账套end

]