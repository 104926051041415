import * as React from 'react';
import classnames from 'classnames';
import { Row, Col } from 'uiw';
import './index.scss'

export default class FormItem extends React.Component<any,any>{
    static defaultProps = {
        inline:true,
        gutter:20,
        width:100
    };
    render(){
        let {children,label,align,className,width,gutter,inline,style} = this.props;
        return(
            <React.Fragment>
                {inline ?
                    <Row align={align} gutter={gutter} className={classnames('custom-form-item',className)} style={style}>
                        <Col fixed style={{width:width}}>
                            {(label && label!=='') ?
                                <p className="label">{label}：</p>
                                :
                                <p></p>
                            }
                        </Col>
                        <Col grow={1} style={{paddingTop:"5px"}}>
                            {children}
                        </Col>
                    </Row>
                    :
                    <div className={classnames('custom-form-item',className)} style={style}>
                        <p className="label label-single">{label}</p>
                        {children}
                    </div>
                }
            </React.Fragment>
        )
    }
}