//延迟加载组件
import * as React from 'react';

export default (importComponent: any) => {
    class AsyncComponent extends React.Component<any, any>{
        constructor(props: any) {
            super(props);
            this.state = {
                component: null
            };
        }

        async componentDidMount() {
            if (this.state.component !== null) {
                return;
            }
            const { default: component } = await importComponent();
            this.setState({
                component: component
            });
        }

        componentWillUnmount() {
            this.setState = (state, callback) => {
                return
            }
        }

        render() {
            const C = this.state.component;
            return (
                C ?
                    <C {...this.props} />
                    :
                    null
            );
        }
    }

    return AsyncComponent;
}