//设置商城商家对应关系
import * as React from 'react';
import { Shadow, Confirm } from '@/components';
import { Button, Table, Radio, Input, Pagination,Loader } from 'uiw';
import API from '@/utils/http';
import style from '../index.module.scss';

export default class ExportProduct extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            exportHtml: '',
            timer: null,
        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isOpen == true) {
            this.getData();
        }
    }

    //关闭
    onClose = () => {
        this.props.onClose && this.props.onClose();
        let { timer } = this.state;
        clearInterval(timer);
        this.setState({ timer: null });
    }


    // 获取数据
    getData = () => {
        let that = this;
        let { queryModel } = this.props;
        let param = {
            mth: "ExportErpProdect",
            erpProductNo: queryModel.EcShopSkuNo,
            erpProductName: queryModel.ErpProductName || null,
            selectType: queryModel.RelationsType,
            ecShopState: queryModel.EcShopState,
            pageType: queryModel.pageType,
            multiDbId: queryModel.multiDbId,
        };
        API.postData(param, '/UI/Products/ajax.ashx', (result: any) => {
            that.setState({ timer: setInterval(that.getExportState, 2000) })
        });
    }

    // 获取导出状态
    getExportState = () => {
        let that = this;
        let { queryModel } = this.props;
        let { timer } = this.state;
        let param = {
            mth: "GetExportResult",
            pageType: queryModel.pageType,
        };
        API.postData(param, '/UI/Products/ajax.ashx', (result: any) => {
            if (!result) {
                clearInterval(timer);
                that.setState({ timer: null });
                that.setState({ exportHtml: "<span>导出失败！请重试</span>" });
            }
            if (result.State == 1)
                return;

            if (result.State == 2) {
                that.setState({ exportHtml: "<span>导出成功！</span><a href=" + result.DownLoadFileUrl + ">点击下载</a>" });
            } else {
                that.setState({ exportHtml: "<span>导出失败！失败原因：" + result.ErrorMsg + "</span>" });
            }
            clearInterval(timer);
            that.setState({ timer: null });
        });
    }




    render() {
        let { isOpen } = this.props;
        let { exportHtml } = this.state;
        return (
            <Shadow
                title='导出商品'
                isOpen={isOpen}
                width={300}
                onClose={this.onClose.bind(this)}
            >
                <div style={{textAlign:'center'}}>
                {
                    exportHtml===''?
                    <Loader tip="导出中..." color="#3686cb" />
                    :
                    <span className={style.uploadBtn} dangerouslySetInnerHTML={{ __html: exportHtml }}></span>
                }
                </div>
            </Shadow>
        )
    }
}