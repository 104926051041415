//loader框
import * as React from 'react';
import * as ReactDom from 'react-dom';


function LoadVersion() {
    //

}

LoadVersion.prototype = {


    box: null,

    show: function () {

        let delay = function () {
            let dom = document.getElementById("qctversion");
            if (!window.serverClientVersion || !window.localVersion || !dom) {
                console.log('延迟执行');
                setTimeout(delay, 2000);
                return;
            }

            if (window.serverClientVersion.code > window.localVersion.code) {
                dom.innerHTML = "<a href='" + window.serverClientVersion.src + "' target='_blank' style='color:red;'>有新版本：" + window.serverClientVersion.name + "</a>";
            } else {
                dom.innerHTML = "";
            }

        };

        setTimeout(delay, 2000);
    },

    getlocationVersion: function () {
        if (window.localVersion) {
            return window.localVersion.name;
        } else {
            return "";
        }
    }
}

export default LoadVersion;