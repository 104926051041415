import * as React from 'react';
import { HashRouter } from 'react-router-dom';
import { Overlay } from 'uiw';
import { IframeMenu, IframeHeader, IframeContent, Guide } from '@/components';
import { default as Home } from '@/pages/home/index';
import '@/assets/css/iframe.scss';
import logo from '@/assets/images/logo.png';
import API from './utils/http';
import GuideA8 from './components/guide/GuideA8';
import GuideV3 from './components/guide/GuideV3';
import GuideHH from './components/guide/GuideHH';
import GuideCM from './components/guide/GuideCM';
import GuideQF from './components/guide/GuideQF';
import GuideKingDee from './components/guide/GuideKingDee';
import GuideYY from './components/guide/GuideYY';
import GuideYYU8 from './components/guide/GuideYYU8';
import GuideYYTPlus from './components/guide/GuideYYTPlus';
import GuideZYJS from './components/guide/GuideZYJS';
import GuideFZ from './components/guide/GuideFZ';
import GuideSP from './components/guide/GuideSP';
import GuideGM from './components/guide/GuideGM';
import GuideQP from './components/guide/GuideQP';
import GuideQP2S from './components/guide/GuideQP2S';
import GuideHYSM from './components/guide/GuideHYSW';
import GuideJieKe from './components/guide/GuideJieKe';
import LoadVersion from './utils/LoadVersion.js';

export default class Iframe extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        let isLogin = API.isLogin("");
        this.state = {
            currentEdition: '',
            tabMenu: [],
            user: '',
            shopId: 0,
            erpType: '',
            disableGuidErpType: ['ZZRJ', 'JRSPRJ', 'YSRJ', 'WBDRP'],// 不显示导航页的ERP
            guidShow: false,
            menuList: [],
            isLogin: isLogin
        }
        //未登录直接到登录页面
        if (isLogin == false) {
            this.props.history.replace('/login');
            return;
        }
    }

    componentDidMount() {

        let that = this;
        //未登录
        if (that.state.isLogin == false) {
            return;
        }

        API.postData({ mth: "GetMenu" }, "/UI/Handler/home.ashx", function (res: any) {

            let data = res.data;
            that.setState({ menuList: data.list, erpType: data.erpType });
            that.defaultTabMenu();
            that.GetGuideCompleteState();
            that.GetLoginInfo();

        });

        //显示版本信息
        let v = new LoadVersion();
        v.show();

    }

    //获取当前路由下的头部菜单
    defaultTabMenu = () => {
        let list: any = this.state.menuList;
        let url = this.props.match.url;
        let name: string = '';
        if (url === '/main') {

        } else {
            let data = list.filter((item: any) => item.Link === url);
            if (data.length > 0) {
                name = data[0].MenuName;
            } else {
                list.map((item: any) => {
                    let dataChildren = (item.children || []).filter((li: any) => li.Link === url);
                    if (dataChildren.length > 0) {
                        return name = dataChildren[0].MenuName
                    } else {
                        return name;
                    }
                })
            }
            this.handleClickMenu(url, name);
        }
    }

    //获取是否已经完成引导
    GetGuideCompleteState = () => {

        let that = this;
        API.postData({ mth: "GetGuideCompleteState" }, "/UI/Handler/SetUp.ashx", function (res: any) {
            console.log(res);

            that.setState({
                guidShow: !res.data
            });

        });
    }

    //获取当前登录信息
    GetLoginInfo = () => {

        let that = this;
        API.postData({ mth: "GetLoginInfo" }, "/UI/Handler/Home.ashx", function (res: any) {

            that.setState({
                user: res.data.userName + "【" + res.data.ecshopName + "】",
                shopId: res.data.shopId
            });

        });
    }



    //点击菜单注入头部tab
    handleClickMenu = (link: string, name: string) => {
        let obj = this.state.tabMenu;
        let isExist = false; //是否已存在
        if (obj.length > 0) {
            for (let i = 0; i < obj.length; i++) {
                obj[i].active = false;
                //判断是否有相同的菜单
                if (link === obj[i].link && name === obj[i].name) {
                    obj[i].active = true;
                    isExist = true;
                }
            };
        }
        if (!isExist) {
            //根据屏幕的宽度显示 不同的菜单个数
            let showTabCount = 3;
            let width = window.screen.width;
            //
            if (width >= 1920) {
                showTabCount = 6;
            }
            else if (width >= 1680) {
                showTabCount = 5;
            }
            else if (width >= 1440) {
                showTabCount = 4;
            }
            //总个数不超过6个
            if (obj.length === showTabCount) {
                obj.shift();
            }
            obj.push({ link: link, name: name, active: true })
        }
        this.setState({
            tabMenu: obj
        })
    }

    //头部切换菜单
    handleChangeMenu = (n: number) => {
        let obj = this.state.tabMenu;
        for (let i = 0; i < obj.length; i++) {
            obj[i].active = false;
        }
        obj[n].active = true;
        this.setState({
            tabMenu: obj
        })
    }

    //头部菜单删除
    handleDelete = (n: number) => {
        let obj = this.state.tabMenu;
        obj.splice(n, 1);
        this.setState({
            tabMenu: obj
        })
    }


    getGuideUI(erpType: string) {
        switch (erpType) {
            case "fxErpV1":
            case "fxErpV3":
            case "fxErpS3":
                return <GuideV3 finished={() => this.setState({ guidShow: false })} />;
                break;
            case "fxErpA8":
                return <GuideA8 finished={() => this.setState({ guidShow: false })} />;
                break;
            case "CM":
                return <GuideCM finished={() => this.setState({ guidShow: false })} />
                break;
            case "HH":
            case "HHErp":
                return <GuideHH finished={() => this.setState({ guidShow: false })} />;
                break;
            case "SP":
                return <GuideSP finished={() => this.setState({ guidShow: false })} />;
                break;
            case "QF":
            case "QFQX":
                return <GuideQF finished={() => this.setState({ guidShow: false })} />;
                break;
            case "KingdeeK3":
            case "KingdeeKIS":
                return <GuideKingDee finished={() => this.setState({ guidShow: false })} />;
                break;
            case "YY":
                return <GuideYY finished={() => this.setState({ guidShow: false })} />;
                break;
            case "YYU8":
                    return <GuideYYU8 finished={() => this.setState({ guidShow: false })} />;
                    break;
            case "YYTPlus":
                return <GuideYYTPlus finished={() => this.setState({ guidShow: false })} />;
                break;
            case "ZYJS":
                return <GuideZYJS finished={() => this.setState({ guidShow: false })} />;
                break;
            case "FZ":
                return <GuideFZ finished={() => this.setState({ guidShow: false })} />;
                break;
            case "GM":
                return <GuideGM finished={() => this.setState({ guidShow: false })} />;
            case "QP":
                return <GuideQP finished={() => this.setState({ guidShow: false })} />;
            case "QP1S":
            case "QP2S":
                return <GuideQP2S finished={() => this.setState({ guidShow: false })} />;
            case "HYSW":
                return <GuideHYSM finished={() => this.setState({ guidShow: false })} />;
            case "JieKe":
                return <GuideJieKe finished={() => this.setState({ guidShow: false })} />;
            default:
                return <Guide finished={() => this.setState({ guidShow: false })} />
                break;
        }
    }

    render() {
        const { currentEdition, tabMenu, user, guidShow, menuList, shopId, erpType, disableGuidErpType, isLogin } = this.state;
        return (

            isLogin &&
            <div className="page-container">
                <HashRouter>
                    <div className="page-slide">
                        <p className="page-title">
                            <img src={logo} alt="" />
                        </p>
                        <div className="page-menu">
                            {/* 菜单部分 */}
                            <IframeMenu menu={menuList} handleClickMenu={this.handleClickMenu} />
                        </div>
                        <div className="page-company">
                            <p className="overflow-area" id="qctversion">当前版本：{currentEdition}</p>
                        </div>
                    </div>
                    <div className="page-wrap">
                        <IframeHeader
                            menuList={tabMenu}
                            user={user}
                            shopId={shopId}
                            handleChangeMenu={this.handleChangeMenu}
                            handleDelete={this.handleDelete}
                            handleSignOut={() => this.props.history.replace('/login')} />
                        <div className="page-content">
                            {/* 主体 */}
                            {
                                tabMenu.length > 0 ?
                                    <IframeContent />
                                    :
                                    <Home handleClickMenu={this.handleClickMenu} />
                            }
                        </div>
                    </div>
                </HashRouter>
                {!disableGuidErpType.find((i: any) => i == erpType) && <Overlay maskClosable={false} isOpen={guidShow} onClose={() => this.setState({ guidShow: false })}>
                    <div>
                        {this.getGuideUI(erpType)}
                    </div>
                </Overlay>}
            </div>
        )
    }
}