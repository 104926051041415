//loader框
import * as React from 'react';
import * as ReactDom from 'react-dom';
import { Message } from 'uiw';

import './loginExpireTips.scss';


function LoginExpireTips() {
    //

}

LoginExpireTips.prototype = {

    id: "qct_tips_login_expire_box",

    box: null,

    fullscreen: true,

    show: function () {

        var _this = this;

        var isExists = document.getElementById(_this.id);
        if (isExists) {
            _this.box = isExists;
            isExists.style.display = "block";
            return;
        }

        let box = document.createElement('div');
        box.id = _this.id;
        box.className = "tips-login-expire-box";
        document.body.appendChild(box);
        _this.box = box;
        ReactDom.render(
            <div className="tips-login-expire-box-ctx">
                <div className="tips-login-expire-box-bg"></div>
                <div className="tips-login-expire-box-inner">
                    <Message showIcon type="info" title="检测到您的账户登录凭证已经过期，即将退出系统！" />
                </div>
            </div>
            ,
            this.box
        );

    },

    close: function (callback: Function) {

        let div = this.box;

        var removeBox = function () {
            div.style.display = "none";
        }

        if (!callback) {
            removeBox();
            return;
        }
        setTimeout(function () {
            removeBox();
            callback();
        }, 1000 * 5);


    }

}

export default LoginExpireTips;