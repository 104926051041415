import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, HashRouter } from 'react-router-dom'
import '@/assets/css/base.scss';
import Iframe from './iframe';
import Login from '@/pages/account/login';
import * as serviceWorker from './serviceWorker';

var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
var isLessIE11 = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1;  //ie11以下
var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;    //ie11
var isEdge = userAgent.indexOf('Edge') > -1 && !isLessIE11;   //edge
let isIE;
if (isLessIE11 || isIE11 || isEdge) {
    isIE = true;
} else {
    isIE = false
}

ReactDOM.render(
    <React.Fragment>
        {isIE ?
            <div className="ieShadow">
                <div className="inner">
                    <p className="cont">检测到您使用的是IE浏览器。为了您更好的体验系统，请切换至经典版模式或者更换为现代浏览器（Chrome谷歌浏览器、Firefox火狐浏览器等）</p>
                    {/* <div className="btn-group">
                        <a href="/ui/index.aspx" className="btn">切换到经典模式</a>
                    </div> */}
                </div>
            </div>
            :
            <HashRouter>
                <Switch>
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/main' component={Iframe} />
                    <Route exact path='/main/*' component={Iframe} />
                    <Route exact path='/multi/*' component={Iframe} />
                    <Route exact path='/' component={Iframe} />
                </Switch>
            </HashRouter>
        }
    </React.Fragment>,
    document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
