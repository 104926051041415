const icons = {
    star:
        '<svg viewBox="0 0 1024 1024"><path d="M730.680889 937.301333a86.698667 86.698667 0 0 1-41.415111-10.467555l-177.265778-95.345778-177.038222 95.345778a89.429333 89.429333 0 0 1-91.022222-4.778667 84.878222 84.878222 0 0 1-35.726223-83.512889l34.816-207.303111-149.959111-149.276444a83.968 83.968 0 0 1-20.252444-86.698667 86.016 86.016 0 0 1 68.266666-56.888889l204.8-30.264889 88.291556-183.637333a87.381333 87.381333 0 0 1 156.558222 0l88.291556 183.637333 204.8 30.264889a86.016 86.016 0 0 1 68.266666 56.888889 83.057778 83.057778 0 0 1-20.48 86.698667l-149.504 149.276444 34.588445 207.303111a84.423111 84.423111 0 0 1-35.726222 83.285334 87.381333 87.381333 0 0 1-49.379556 15.473777z" p-id="4892"></path></svg>',
    todo:
        '<svg viewBox="0 0 1024 1024"><path d="M742.4 588.8a192 192 0 1 0 192 192 191.488 191.488 0 0 0-192-192z m-37.888 230.4v-115.712h39.424v77.312H819.2V819.2z m-230.912-37.888a228.864 228.864 0 0 1 76.8-192H281.6c-44.544 0-47.104-38.4 0-38.4h268.8c11.776 0 16.896 5.632 14.848 16.896a244.224 244.224 0 0 1 141.312-44.544 303.616 303.616 0 0 1 189.44 66.048v-460.8A87.04 87.04 0 0 0 819.2 51.2H166.4a91.648 91.648 0 0 0-76.8 76.8v729.6a102.4 102.4 0 0 0 76.8 76.8h384a160.256 160.256 0 0 1-76.8-153.6z m-204.8-537.6H716.8a28.672 28.672 0 0 1 24.064 10.752c8.704 13.824-5.632 27.648-23.552 27.648H269.312a28.16 28.16 0 0 1-23.552-10.752c-8.704-15.36 5.632-28.16 23.552-28.16z m0 153.6H716.8a28.672 28.672 0 0 1 24.064 10.752c8.704 13.824-5.632 27.648-23.552 27.648H269.312a28.16 28.16 0 0 1-23.552-10.752c-8.704-15.36 5.632-28.16 23.552-28.16z" p-id="1117"></path></svg>',
    chart:
        '<svg viewBox="0 0 1024 1024"><path d="M778.752 857.088H245.248a38.4 38.4 0 1 0 0 76.288h533.504a39.424 39.424 0 0 0 40.448-38.4 36.864 36.864 0 0 0-11.776-27.136 41.984 41.984 0 0 0-28.672-10.752z m111.616-724.48a153.6 153.6 0 0 0-106.496-43.008H240.128a153.6 153.6 0 0 0-106.496 43.008 145.92 145.92 0 0 0-44.032 102.4v397.312a145.92 145.92 0 0 0 44.032 102.4 153.6 153.6 0 0 0 106.496 43.008h543.744a153.6 153.6 0 0 0 106.496-43.008 145.92 145.92 0 0 0 44.032-102.4V236.544a145.92 145.92 0 0 0-44.032-103.936zM780.8 358.4l-230.4 230.4-153.6-153.6-115.2 115.2h-38.4V512l153.6-153.6 153.6 153.6 192-192h38.4z" p-id="1057"></path></svg>',
    notice:
        '<svg viewBox="0 0 1024 1024"><path d="M551.424 91.648l171.52 172.032h153.6a79.872 79.872 0 0 1 76.8 76.8v537.6a82.432 82.432 0 0 1-76.8 76.8H146.944a79.36 79.36 0 0 1-76.288-76.8V340.48a78.336 78.336 0 0 1 76.288-76.8h153.6l172.032-172.032a51.2 51.2 0 0 1 33.792-19.456 58.88 58.88 0 0 1 45.056 19.456z m21.504 594.944h-276.48a31.744 31.744 0 0 0-24.064 11.264 39.936 39.936 0 0 0-10.24 27.136 41.984 41.984 0 0 0 9.728 27.136 33.792 33.792 0 0 0 24.576 11.264h276.48a33.792 33.792 0 0 0 24.576-11.264 41.472 41.472 0 0 0 9.728-26.624 38.912 38.912 0 0 0-9.728-27.648 33.792 33.792 0 0 0-24.576-11.264z m157.696-193.024H293.376a28.16 28.16 0 0 0-22.016 11.264 40.96 40.96 0 0 0-9.216 26.624 45.568 45.568 0 0 0 8.704 27.648 30.208 30.208 0 0 0 22.528 11.264h437.248a28.16 28.16 0 0 0 22.016-11.264 41.472 41.472 0 0 0 9.216-27.136 44.032 44.032 0 0 0-8.704-27.136 30.208 30.208 0 0 0-22.528-11.264z m-238.08-307.2l-76.8 76.8h192.512l-76.8-76.8z" p-id="1327"></path></svg>',
    msg:
        '<svg viewBox="0 0 1024 1024"><path d="M470.016 109.056h83.968A391.68 391.68 0 0 1 934.4 512v403.456H470.016A392.192 392.192 0 0 1 89.6 512a391.68 391.68 0 0 1 380.416-402.944z m-150.016 498.688a40.448 40.448 0 0 0 41.984 38.4h300.544a40.448 40.448 0 0 0 41.984-38.4 40.448 40.448 0 0 0-41.984-38.4H361.984a40.448 40.448 0 0 0-41.984 38.4z m-38.4-153.6a40.448 40.448 0 0 0 41.984 38.4h377.344a40.448 40.448 0 0 0 41.984-38.4 40.448 40.448 0 0 0-41.984-38.4H323.584a40.448 40.448 0 0 0-41.984 38.4z" p-id="1087"></path></svg>',
    home:
        '<svg viewBox="0 0 1024 1024"><path d="M912.091429 332.068571a10.24 10.24 0 0 0 0-5.12L832.365714 146.285714A99.474286 99.474286 0 0 0 731.428571 86.308571H292.571429a95.817143 95.817143 0 0 0-95.085715 58.514286L119.222857 326.948571a10.24 10.24 0 0 1 0 4.388572 365.714286 365.714286 0 0 0-9.508571 84.114286c0 124.342857 201.874286 109.714286 174.08 109.714285a141.165714 141.165714 0 0 0 116.297143-54.857143c19.748571 35.108571 41.691429 54.857143 116.297142 54.857143a154.331429 154.331429 0 0 0 116.297143-54.857143 174.811429 174.811429 0 0 0 116.297143 54.857143c41.691429 0 174.08-12.434286 174.08-109.714285a346.697143 346.697143 0 0 0-10.971428-83.382858z m-196.754286 28.525715H315.245714a30.72 30.72 0 0 1-32.914285-27.794286 30.72 30.72 0 0 1 32.914285-27.062857h400.091429a29.988571 29.988571 0 0 1 32.182857 27.062857 30.72 30.72 0 0 1-32.182857 27.794286zM631.222857 525.165714A139.702857 139.702857 0 0 1 512 580.022857a122.88 122.88 0 0 1-116.297143-54.857143 136.777143 136.777143 0 0 1-116.297143 54.857143 176.274286 176.274286 0 0 1-115.565714-30.72v268.434286a110.445714 110.445714 0 0 0 115.565714 109.714286h465.188572a118.491429 118.491429 0 0 0 115.565714-109.714286V552.96a172.617143 172.617143 0 0 1-115.565714 27.062857 119.954286 119.954286 0 0 1-113.371429-54.857143z" p-id="1207"></path></svg>',
    base:
        '<svg viewBox="0 0 1024 1024"><path d="M786.285714 128H731.428571v54.857143a59.245714 59.245714 0 0 1-54.857142 54.857143h-329.142858A58.514286 58.514286 0 0 1 292.571429 182.857143v-54.857143h-54.857143a116.297143 116.297143 0 0 0-109.714286 109.714286v603.428571a120.685714 120.685714 0 0 0 109.714286 109.714286h548.571428a134.582857 134.582857 0 0 0 109.714286-109.714286V237.714286a124.342857 124.342857 0 0 0-109.714286-109.714286zM704.365714 731.428571H319.634286A27.794286 27.794286 0 0 1 292.571429 703.634286a27.062857 27.062857 0 0 1 27.062857-27.062857h384.731428a27.062857 27.062857 0 0 1 27.062857 27.062857 27.794286 27.794286 0 0 1-27.062857 27.794285z m0-219.428571H319.634286a27.794286 27.794286 0 0 1 0-54.857143h384.731428a27.794286 27.794286 0 0 1 0 54.857143zM365.714286 183.588571h292.571428a16.822857 16.822857 0 0 0 18.285715-14.628571V87.771429A16.822857 16.822857 0 0 0 658.285714 73.142857H365.714286a16.822857 16.822857 0 0 0-18.285715 14.628572v81.188571a16.822857 16.822857 0 0 0 18.285715 14.628571z" p-id="1267"></path></svg>',
    price:
        '<svg viewBox="0 0 1024 1024"><path d="M512 73.142857a438.857143 438.857143 0 1 0 438.857143 438.857143 438.857143 438.857143 0 0 0-438.857143-438.857143z m164.571429 274.285714L566.857143 512h109.714286v54.857143h-109.714286v54.857143h109.714286v54.857143h-109.714286v109.714285h-109.714286v-109.714285h-109.714286V621.714286h109.714286v-54.857143h-109.714286V512h109.714286l-109.714286-164.571429a38.765714 38.765714 0 0 1 0-54.857142 43.154286 43.154286 0 0 1 54.857143 0L512 457.142857S599.771429 315.245714 621.714286 292.571429a38.034286 38.034286 0 0 1 54.857143 0 42.422857 42.422857 0 0 1 0 54.857142z" p-id="1147"></path></svg>',
    bill:
        '<svg viewBox="0 0 1024 1024"><path d="M237.714286 73.142857c-80.457143 0-109.714286 32.914286-109.714286 109.714286v746.057143a21.942857 21.942857 0 0 0 30.72 17.554285c11.702857-7.314286 124.342857-108.251429 124.342857-108.251428a38.765714 38.765714 0 0 1 38.034286 0L490.057143 943.542857a47.542857 47.542857 0 0 0 21.942857 7.314286 43.885714 43.885714 0 0 0 21.211429 0l177.737142-106.788572a35.84 35.84 0 0 1 38.034286 0s109.714286 100.937143 119.954286 107.52a18.285714 18.285714 0 0 0 27.794286-15.36V180.662857A100.937143 100.937143 0 0 0 786.285714 73.142857zM292.571429 564.662857V512h438.857142v54.857143z m0-219.428571V292.571429h438.857142v54.857142z" p-id="1177"></path></svg>',
    set:
        '<svg viewBox="0 0 1024 1024"><path d="M941.348571 438.857143c-5.12-24.868571-19.748571-40.96-38.034285-40.228572h-4.388572a113.371429 113.371429 0 0 1-114.102857-113.371428 124.342857 124.342857 0 0 1 10.24-43.154286 51.2 51.2 0 0 0-17.554286-62.171428l-109.714285-59.977143a49.737143 49.737143 0 0 0-21.211429-4.388572 59.245714 59.245714 0 0 0-40.96 16.822857A160.182857 160.182857 0 0 1 512 178.468571a162.377143 162.377143 0 0 1-90.697143-49.737142 57.051429 57.051429 0 0 0-41.691428-16.822858 48.274286 48.274286 0 0 0-20.48 3.657143l-113.371429 61.44a51.2 51.2 0 0 0-17.554286 61.44 108.251429 108.251429 0 0 1 10.24 43.154286 113.371429 113.371429 0 0 1-114.834285 115.565714h-3.657143c-18.285714 0-33.645714 16.091429-38.034286 40.96A488.594286 488.594286 0 0 0 73.142857 523.702857a476.16 476.16 0 0 0 9.508572 84.845714c4.388571 24.868571 19.017143 40.96 37.302857 40.96h4.388571a113.371429 113.371429 0 0 1 114.102857 113.371429 124.342857 124.342857 0 0 1-10.24 43.154286 51.2 51.2 0 0 0 17.554286 62.171428l107.52 59.245715a52.662857 52.662857 0 0 0 21.211429 4.388571 57.051429 57.051429 0 0 0 41.691428-17.554286A166.034286 166.034286 0 0 1 512 863.085714a157.988571 157.988571 0 0 1 92.16 54.125715 57.051429 57.051429 0 0 0 41.691429 17.554285 46.811429 46.811429 0 0 0 20.48-4.388571l111.177142-61.44a51.2 51.2 0 0 0 17.554286-62.171429 124.342857 124.342857 0 0 1-10.24-43.154285 114.102857 114.102857 0 0 1 114.102857-113.371429h3.657143c18.285714 0 33.645714-15.36 38.765714-40.228571A641.462857 641.462857 0 0 0 950.857143 522.971429 641.462857 641.462857 0 0 0 941.348571 438.857143zM512 673.645714a151.405714 151.405714 0 1 1 146.285714-151.405714 151.405714 151.405714 0 0 1-146.285714 151.405714z m0 0" p-id="1297"></path></svg>'
};

export default icons;
