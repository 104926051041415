//默认主页
import * as React from 'react';
import { Row, Col, Icon, Button } from 'uiw';
import ReactEcharts from 'echarts-for-react';
import { SvgIcon, Alert } from '@/components';
import './index.scss'
import API from '@/utils/http';
import { Link } from 'react-router-dom';
import LoadVersion from '../../utils/LoadVersion.js';
import LoadHelp from '../../utils/LoadHelp.js';

export default class Home extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            // 代办
            todoList: this.GetTodoStatisticModel(null),
            notice: [
            ],
            // 图表
            getOption: this.GetChartsModel(null),

            ErpVersion: '',
            ErpDBName: '',

            QCTVersion: '全程通',

            remark: ''
        }
    }

    // 修复对已卸载的组件进行更新错误
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    componentDidMount() {

        this.GetTodoStatistic();
        this.GetOrderSource();
        this.GetErpVersion();

        let that = this;
        setTimeout(() => {
            let v = new LoadVersion();
            let versionName = v.getlocationVersion();
            that.setState({ QCTVersion: versionName });

        }, 2000);

        setTimeout(() => {
            that.showHelpList();
        }, 100);
    }

    showHelpList() {
        let that = this;
        let delay = function (res: any) {
            that.setState({ notice: res });
        };
        let v = new LoadHelp();
        v.show(delay);
    }


    GetErpVersion() {
        let that = this;

        API.postData({ mth: "GetVersion" }, "/UI/Handler/Home.ashx", (res: any) => {

            that.setState({
                ErpVersion: res.data.erpVersion,
                ErpDBName: res.data.erpDBName
            });

        });

    }

    // 获取代办事项模型类
    GetTodoStatisticModel = (item: any) => {
        let model = [
            {
                icon: 'order',
                name: '订单',
                num: item?.OrderTotal ?? 0,
                url: {
                    name: item?.OrderTotalUrl?.Name,
                    path: item?.OrderTotalUrl?.Path,
                },
                detail: [
                    {
                        name: '同步失败',
                        num: item?.OrderSyncFailedCount ?? 0,
                        url: {
                            name: item?.OrderSyncFailedCountUrl?.Name,
                            path: item?.OrderSyncFailedCountUrl?.Path + "/1",
                        },
                    },
                    {
                        name: '发货失败',
                        num: item?.OrderSendGoodsFailedCount ?? 0,
                        url: {
                            name: item?.OrderSendGoodsFailedCountUrl?.Name,
                            path: item?.OrderSendGoodsFailedCountUrl?.Path,
                        },
                    },
                ]
            },
            {
                icon: 'goods',
                name: '商品',
                num: item?.ProductTotal ?? 0,
                url: {
                    name: item?.ProductTotalUrl?.Name,
                    path: item?.ProductTotalUrl?.Path,
                },
                detail: [
                    {
                        name: '同步失败',
                        num: item?.ProductSyncFailedCount ?? 0,
                        url: {
                            name: item?.ProductSyncFailedCountUrl?.Name,
                            path: item?.ProductSyncFailedCountUrl?.Path + '/2',
                        },
                    }
                ]
            },
            {
                icon: 'price',
                name: '价格',
                priceDetail: [
                    {
                        name: '基础价格',
                        fail: item?.BasePriceSyncFailedCount ?? 0,
                        url: {
                            name: item?.BasePriceSyncFailedCountUrl?.Name,
                            path: item?.BasePriceSyncFailedCountUrl?.Path + '/1',
                        },
                    },
                    {
                        name: '预设售价',
                        fail: item?.PreSalePriceSyncFailedCount ?? 0,
                        url: {
                            name: item?.PreSalePriceSyncFailedCountUrl?.Name,
                            path: item?.PreSalePriceSyncFailedCountUrl?.Path + '/2',
                        },
                    },
                    {
                        name: '价格跟踪',
                        fail: item?.PriceTraceSyncFailedCount ?? 0,
                        url: {
                            name: item?.PriceTraceSyncFailedCountUrl?.Name,
                            path: item?.PriceTraceSyncFailedCountUrl?.Path + '/1',
                        },
                    },
                ]
            },
            {
                icon: 'unit',
                name: '往来单位',
                num: item?.CustomerTotal ?? 0,
                url: {
                    name: item?.CustomerTotalUrl?.Name,
                    path: item?.CustomerTotalUrl?.Path,
                },
                detail: [
                    {
                        name: '同步失败',
                        num: item?.CustomerSyncFailedCount ?? 0,
                        url: {
                            name: item?.CustomerSyncFailedCountUrl?.Name,
                            path: item?.CustomerSyncFailedCountUrl?.Path + '/2',
                        },
                    }
                ]
            },
        ];
        return model;
    }

    // 获取图表模型类
    GetChartsModel = (item: any) => {
        let model = {
            color: ["#f18100", "#00a1e9"],
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['线上订单数量', '线下ERP订单数量']
            },
            xAxis: {
                data: item?.Title ?? []
            },
            yAxis: {},
            series: [
                {
                    name: '线上订单数量',
                    type: 'line',
                    data: item?.EcShopData ?? [],
                    smooth: true,
                    symbol: 'none',
                },
                {
                    name: '线下ERP订单数量',
                    type: 'line',
                    data: item?.ErpData ?? [],
                    smooth: true,
                    symbol: 'none',
                }
            ]
        };
        return model;
    }

    // 获取代办事项
    GetTodoStatistic = () => {

        let that = this;

        API.postData({ mth: "GetTodoStatistic" }, "/UI/Handler/Home.ashx", (res: any) => {

            that.setState({
                todoList: that.GetTodoStatisticModel(res.data)
            });

        });

    }

    // 获取图表
    GetOrderSource = () => {
        let that = this;

        API.postData({ mth: "GetOrderSource" }, "/UI/Handler/Home.ashx", (res: any) => {

            that.setState({
                getOption: that.GetChartsModel(res.data)
            });

        });
    }

    //点击菜单
    handleClick = (link: string, name: string) => {
        this.props.handleClickMenu && this.props.handleClickMenu(link, name);
    }


    //提交建议
    sumbitSuggestion() {
        let that = this;
        let remark = that.state.remark;
        if (remark == "" || remark == null) {
            Alert("请输入需求反馈");
            return;
        }

        API.postData({ mth: "SubmitSuggestion", comment: remark }, "/UI/Handler/Home.ashx", (res: any) => {

            if (res.code == 0) {
                Alert("您的需求反馈已收到！如果您的需求被采纳将会在后续的版本中进行改善");
                that.setState({ remark: "" });
            } else {
                Alert(res.msg);
            }
        });
    }

    //代办事项
    todoList = () => {
        let { todoList } = this.state;
        return (
            <React.Fragment>
                {todoList.map((item: any, index: number) =>
                    <li key={index} className={item.icon}>
                        <div className="inner box box-center">
                            <i className="icon"></i>
                            <div className="box1 cont box box-vertical overflow">
                                <p className="mt">
                                    {item.name}
                                    <span>
                                        {item.num && <Link className="box1" to={item.url?.path} onClick={() => this.handleClick(item.url?.path, item.url?.name)}>{item.num}</Link>}
                                    </span>
                                </p>
                                <div className="detail-list">
                                    {item.detail ?
                                        <React.Fragment>
                                            {
                                                item.detail.map((li: any, key: number) =>
                                                    <div key={key} className="half overflow">
                                                        {li.name}
                                                        <span className="num">
                                                            {li.num && <Link className="box1" to={li.url?.path} onClick={() => this.handleClick(li.url?.path, li.url?.name)}>{li.num}</Link>}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {
                                                item.priceDetail.map((li: any, key: number) =>
                                                    <div key={key} className="all box box-center">
                                                        <p className="box1">{li.name}</p>
                                                        <p className="box1 overflow">
                                                            同步失败
                                                            <span className="num">
                                                                {li.fail && <Link className="box1" to={li.url?.path} onClick={() => this.handleClick(li.url?.path, li.url?.name)}>{li.fail}</Link>}
                                                            </span>
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                )}
            </React.Fragment>
        )
    }

    render() {
        let { notice, ErpVersion, getOption, QCTVersion, ErpDBName, remark } = this.state;
        return (
            <div className="home-system">
                <Row gutter={10}>
                    <Col span="18">
                        <div className="section">
                            <div className="mt no-line box box-center">
                                <SvgIcon type="todo" color="#3686cb" size="18" />
                                <p>待办事项</p>
                            </div>
                        </div>
                        <ul className="todo-list box">
                            {this.todoList()}
                        </ul>
                        <div className="section">
                            <div className="mt no-line box box-center">
                                <SvgIcon type="chart" color="#3686cb" size="18" />
                                <p>订单来源走向(本报表10分钟更新一次)</p>
                            </div>
                            <div className="mc echarts-mc">
                                <ReactEcharts option={getOption} notMerge={true} lazyUpdate={true} />
                            </div>
                        </div>
                    </Col>
                    <Col span="6">
                        <div className="section">
                            <div className="mt box box-center">
                                <p>{QCTVersion}</p>
                            </div>
                            <div className="mc edition-mc">
                                <div className="item box box-center">
                                    <p className="box1">ERP版本信息：{ErpVersion}</p>

                                </div>
                                <div className="item box box-center">
                                    <p className="box1">ERP账套信息：{ErpDBName}</p>
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="mt box box-center">
                                <SvgIcon type="msg" color="#3686cb" size="18" />
                                <p>常见问题</p>
                            </div>
                            <div className="mc">
                                <ul className="notice-list">
                                    {
                                        notice.map((item: any, index: number) =>
                                            <li key={index} className="overflow"><a href={item.link} target="_blank">{item.title}</a></li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="section">
                            <div className="mt box box-center">
                                <SvgIcon type="notice" color="#3686cb" size="18" />
                                <p>需求反馈</p>
                            </div>
                            <div className="mc feedback-mc">
                                <textarea className="textarea" value={remark}
                                    onChange={(e: any) => {
                                        this.setState({ remark: e.target.value })
                                    }}></textarea>
                                <div className="btn">
                                    <Button type="primary" onClick={() => {
                                        this.sumbitSuggestion();
                                    }}>提交</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}