//设置预设售价对应关系
import * as React from 'react';
import { Shadow, Pagi } from '@/components';
import { Button, Table, Radio } from 'uiw';
import API from '@/utils/http';
import style from '../index.module.scss';

export default class SetPriceRelations extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            choosed: {},
            columns: [
                {
                    title: '',
                    width: 60,
                    key: 'checked',
                    render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => (
                        <div className={style.radioShadow}>
                            <div className={style.bg}></div>
                            <Radio name='unit' checked={rowData.checked}></Radio>
                        </div>
                    )
                },
                { title: '客户类型价', key: 'storeTypeName' }
            ],
            dataSource: [],
            page: {
                pageIndex: 1,
                pageSize: 10,
                count: 0
            },
        }
    }
    componentDidMount() {

    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isOpen == true) {
            this.getData(1);
        }
    }

    //关闭
    onClose = () => {
        this.props.onClose && this.props.onClose();
    }
    //确定
    onConfirm = () => {
        let { choosed } = this.state;
        this.props.onConfirm && this.props.onConfirm(choosed);
    }

    //获取数据
    getData = (n: number) => {
        let param = {
            mth: 'GetEcShopPriceType',
            pageCurrent: n
        }
        API.postData(param, '/UI/Price/ajax.ashx', (res: any) => {
            let obj: any = {};
            obj.pageIndex = n;
            obj.pageSize = res.pageSize;
            obj.count = res.rowCount;
            this.setState({
                dataSource: res.data,
                page: obj
            })
        })
    }

    render() {
        let { isOpen, selectedObj } = this.props;
        let { columns, dataSource, page, choosed } = this.state;
        dataSource = dataSource.map((item: any, idx: number) => {
            const isChecked = choosed.storeTypeID === item.storeTypeID;
            return { checked: isChecked, ...item };
        });
        let discrib = "（ERP预设售价原始名称：" + selectedObj.ErpPriceOldName + " ERP预设售价现名称：" + selectedObj.ErpPriceNewName + "）";
        return (
            <Shadow
                title="设置预设售价对应关系"
                discrib={discrib}
                isOpen={isOpen}
                width={800}
                onClose={this.onClose.bind(this)}
                footer={
                    <Button type="primary" onClick={this.onConfirm.bind(this)}>确定</Button>
                }
            >
                <div style={{ height: 400, overflow: 'auto' }}>
                    <Table
                        className="custom-table custom-table-hasline"
                        columns={columns}
                        data={dataSource}
                        onCell={(rowData) => {
                            this.setState({
                                choosed: rowData
                            })
                        }}
                    />
                </div>
                <div className="align-right martop">
                    <Pagi alignment="right"
                        current={page.pageIndex}
                        pageSize={page.pageSize}
                        showCount={false}
                        total={page.count}
                        onChange={(current: number, total: number, pageSize: number) => {
                            this.getData(current);
                        }} />
                </div>
            </Shadow>
        )
    }
}