import Axios from 'axios';
import qs from 'qs';
import Loading from './loading';
import LoginExpireTips from './loginExpireTips';
const axios = Axios.create();
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';


var API = {

    apiUrl: "",

    /*提交数据*/
    postData: function (data, path, callback, expCallback = (params) => { }) {

        let msg = new LoginExpireTips();
        if (this.isLogin(path) == false) {
            msg.show();
            msg.close(function () {
                window.location.hash = "/login";
            });
            return;
        }

        let loading = new Loading();
        let that = this;
        let str = qs.stringify(data);
        loading.show();

        axios({
            url: that.apiUrl + path,
            method: "POST",
            data: str
        }).then(function (res) {

            loading.close();
            let jumpUrl = "";
            //处理类型问题
            let code = res.data && res.data.code ? String(res.data.code) : "";
            switch (code) {

                case "88887777": //未配置账套
                    jumpUrl = "/UI/shop/selectdb.aspx";
                    window.location.href = jumpUrl;
                    return;
                case "88888888": //登录
                    msg.show();
                    jumpUrl = "/login";
                    msg.close(function () {
                        window.location.hash = jumpUrl;
                    });
                    break;
                case "88889999": //未设置数据信息
                    jumpUrl = "/UI/shop/SetShop.aspx";

                    window.location.href = jumpUrl;
                    return;
                default: break;
            }
            if (jumpUrl != "") {
                return;
            }


            callback(res.data);

        }).catch(function (params) {
           // Alert(params.message);
            loading.close();
            if (expCallback && typeof (expCallback) == 'function')
                expCallback(params);
        });
    },

    isLogin: function (path) {
        //console.log(path);
        if (path.toLowerCase() == "/login.aspx") {
            return true;
        }

        return this.getCookie("qct_session") != null;
    },

    getCookie: function (name) {
        var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        if (arr = document.cookie.match(reg))
            return unescape(arr[2]);
        else
            return null;
    }
}

export default API;