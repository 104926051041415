//框架菜单
import * as React from 'react';
import { Link } from 'react-router-dom';
import { SvgIcon } from '@/components';
import './index.scss';

export default class IframeMenu extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state={};
    }

    //点击菜单
    handleClick = (link: string, name: string) => {
        this.props.handleClickMenu && this.props.handleClickMenu(link, name);
    }

    render() {
        const { menu } = this.props;
        return (
            <ul className="menu-list">
                {
                    menu.map((item: any, index: number) =>
                        <li key={index}>
                            <div className="box box-center">
                                <SvgIcon type={item.icon} color="#3686cb" size="18" />
                                {item.path === '' ?
                                    <p className="box1">{item.name}</p>
                                    :
                                    <Link className="box1" to={item.path} onClick={() => this.handleClick(item.path, item.name)}>{item.name}</Link>
                                }
                            </div>
                            {item.hasChild &&
                                <div className="menu-box" style={{ top: item.top + 'px' }}>
                                    <p className="arrow"></p>
                                    <div className="menu-li-box">
                                        <ul>
                                            {
                                                item.children.map((secondItem: any, secondIndex: number) =>
                                                    <li key={secondIndex}>
                                                        {
                                                            <Link to={secondItem.path} className="clickable-item" onClick={() => this.handleClick(secondItem.path, secondItem.name)}>
                                                                {secondItem.name}
                                                            </Link>
                                                        }
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </li>
                    )
                }
            </ul>
        )
    }
}