import * as React from 'react';
import { Button, Message, Select, Radio, RadioGroup } from 'uiw';
import './index.scss';
import successImg from '@/assets/images/success.png'
import API from '@/utils/http';
import { Tab, FormItem, ClickInput, Shadow, Alert } from '@/components';
import { CustomerShadow, GoodsShadow, WareHouseShadow, UserShadow, StypeShadow, DepartmentShadow } from '@/widget/shadow';
import CustomerHalfShadow from '@/pages/setup/data/CustomerHalfShadow';
import GoodsHalfShadow from '@/pages/setup/data/GoodsHalfShadow';

export default class GuideJieKe extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            finished: false,
            stepIndex: 0,
            stepStr: ['商品同步设置', '价格同步设置', '库存同步设置', '往来单位同步设置', '订单信息设置'],
            stepProp: ['ProductSyncSetting', 'PriceSyncSetting', 'StorageStockSyncSetting', 'CustomerSyncSetting', 'OrderInfoSetting'],
            stepModel: {
                "ProductSyncSetting": {
                    "Value": "0",
                    "List": [
                        {
                            "Key": "0",
                            "Value": "不同步"
                        },
                        {
                            "Key": "1",
                            "Value": "全部"
                        },
                        {
                            "Key": "2",
                            "Value": "部分"
                        }
                    ]
                },
                "PriceSyncSetting": {
                    "SalePriceSetting": {
                        "Value": "0",
                        "List": []
                    },
                    "MktPriceSetting": {
                        "Value": "0",
                        "List": []
                    },
                    "CostPriceSetting": {
                        "Value": "0",
                        "List": []
                    }
                },
                "StorageStockSyncSetting": {
                    "StorageSyncSetting": {
                        "Value": "0",
                        "List": [
                            {
                                "Key": "0",
                                "Value": "不同步"
                            },
                            {
                                "Key": "1",
                                "Value": "同步"
                            }
                        ]
                    },
                    "StockSyncSetting": {
                        "Value": "0",
                        "List": [
                            {
                                "Key": "0",
                                "Value": "不同步"
                            },
                            {
                                "Key": "1",
                                "Value": "同步"
                            }
                        ]
                    },
                },
                "CustomerSyncSetting": {
                    "Value": "0",
                    "List": [
                        {
                            "Key": "0",
                            "Value": "不同步"
                        },
                        {
                            "Key": "1",
                            "Value": "全部"
                        },
                        {
                            "Key": "2",
                            "Value": "部分"
                        }
                    ]
                },
                "OrderInfoSetting": {
                    "OrderTypeSetting": {
                        "Value": "SalesSlip",
                        "List": [
                            {
                                "Key": "SalesSlip",
                                "Value": "订单草稿"
                            },
                            {
                                "Key": "SalesWarehousingReceipt",
                                "Value": "出库单草稿"
                            }
                        ]
                    },
                    "Btype": {
                        "Brec": 0,
                        "BUserCode": "",
                        "BFullName": "",
                        "Soncount": 0,
                        "ParID": "",
                        "BtypeID": "",
                        "BtypeID2": "",
                        "BtypeID3": null,
                        "EtypeID": null,
                        "EFullName": null,
                        "DtypeID": null,
                        "STypeId": null,
                        "KtypeId": null,
                        "IsHasChild": false,
                        "EcShopPriceId": null
                    },
                    "Etype": {
                        "EtypeID": "",
                        "Erec": 0,
                        "EUserCode": "",
                        "EFullName": "",
                        "Soncount": 0,
                        "ParID": "",
                        "DtypeID": null,
                        "StypeId": null,
                        "KtypeId": null
                    },
                    "Ktype": {
                        "KtypeID": "",
                        "KUserCode": "",
                        "KFullName": "",
                        "Soncount": 0,
                        "ParID": "",
                        "Krec": 0,
                        "StypeID": ""
                    },
                    "Dtype": {
                        "DtypeID": "",
                        "DUserCode": "",
                        "DFullName": "",
                        "Soncount": 0,
                        "ParID": "",
                        "Drec": 0,
                    },
                }
            },
            stypeShadow: false,
            customerShadow: false,
            warehouseShadow: false,
            dtypeShadow: false,
            userShadow: false,
            rule: [
                '商城【客户编号】对应ERP【单位编号】',
                '商城【客户名称】对应ERP【单位全名】',
                '商城【联系电话】对应ERP【手机一】',
                '商城【登录账号】对应ERP【手机一】，若ERP“手机一”值为空则对应上传【单位编号】'
            ],
            customerTipShadow: false,
            customerHalfShadow: false,
            goodsTipShadow: false,
            goodsHalfShadow: false,
        }
    }

    componentDidMount() {
        this.getGuideList();
    }


    getGuideList = () => {
        let that = this;
        let { stepProp, stepIndex } = that.state;
        let param = {
            mth: "GetGuideList"
        }
        API.postData(param, "/UI/Handler/GuideComplete.ashx", (res: any) => {
            console.log(res.data);
            let stepModel = res.data;
            that.setState({ stepModel: stepModel });
            while (!stepModel[stepProp[stepIndex]]) {
                that.nextStep();
                stepIndex++;
            }
        })
    }

    //改变选择框
    changeChooseSec = (q: any, select: any) => {
        let { stepModel } = this.state;
        stepModel[q].Value = select;
        this.setState({ stepModel: stepModel }, () => {
            // 商品同步
            if (q == 'ProductSyncSetting' && select != "0") {
                this.setState({
                    goodsTipShadow: true
                })
            }
            // 往来单位同步
            if (q === 'CustomerSyncSetting' && select != "0") {
                this.setState({
                    customerTipShadow: true
                })
            }
        });
    }

    // 选择框
    chooseSec = (q: any, model: any) => {
        let list = model.List;
        return (
            <ul className="choose-list">
                {list.map((item: any, index: number) =>
                    <li key={item.Key} className={item.Key === model.Value ? 'active' : ''} onClick={this.changeChooseSec.bind(this, q, item.Key)}>
                        <p>{item.Value}</p>
                    </li>
                )}
            </ul>
        )
    }

    // 改变下拉框
    changeSelectSec = (prop1: string, prop2: string, e: any) => {
        let { stepModel } = this.state;
        stepModel[prop1][prop2].Value = e.target.value;
        this.setState({ stepModel: stepModel });
    }

    // 下拉框
    selectSec = (list: Array<any>, active: any, prop1: string, prop2: string) => {
        let selected = list.find((i: any) => i.Key == active);

        return (
            <Select defaultValue={selected?.Key ?? active} onChange={this.changeSelectSec.bind(this, prop1, prop2)}>
                {!selected && <Select.Option key="" value="">请选择</Select.Option>}
                {list.map((item: any, index: number) =>
                    <Select.Option key={item.Key} value={item.Key}>{item.Value}</Select.Option>
                )}
            </Select>
        )
    }

    // 单选框
    changeSelect = (prop1: string, prop2: string, val: any) => {
        let { stepModel } = this.state;
        if (prop1 && prop2) {
            stepModel[prop1][prop2].Value = val;
            this.setState({ stepModel: stepModel });
        }
    }

    // 单选
    changeRadio = (prop1: string, prop2: string, e: any) => {
        let val = e.target.value;
        let { stepModel } = this.state;
        if (prop1 && prop2) {
            stepModel[prop1][prop2].Value = val;
            this.setState({ stepModel: stepModel });
        }
    }

    //下一步
    nextStep = () => {
        let { stepIndex, stepProp, stepModel } = this.state;
        if (stepIndex === 4) {
            this.submit();
        } else {
            let model = null;
            do {
                stepIndex++;
                if (stepIndex > 4) {
                    stepIndex = 0;
                }
                model = stepModel[stepProp[stepIndex]]
            } while (!model)
            this.setState({ stepIndex: stepIndex });
        }
    }

    //上一步
    prevStep = () => {
        let { stepIndex, stepProp, stepModel } = this.state;
        let model = null;
        do {
            stepIndex--;
            if (stepIndex < 0) {
                stepIndex = 4;
            }
            model = stepModel[stepProp[stepIndex]]
        } while (!model)
        this.setState({ stepIndex: stepIndex });
    }

    // 保存
    submit = () => {
        let { stepModel } = this.state;

        if (!this.verification(stepModel))
            return;

        let param = {
            mth: "SaveConfigGuide",
            model: JSON.stringify(stepModel)
        };
        API.postData(param, "/UI/Handler/GuideComplete.ashx", (res: any) => {
            if (res.code) {
                Alert(res.msg);
                return;
            }
            this.setState({ finished: true });
            this.updateImport();
        })
    }

    // 验证
    verification = (stepModel: any) => {
        if (stepModel.PriceSyncSetting.SalePriceSetting && !stepModel.PriceSyncSetting.SalePriceSetting.Value) {
            Alert("请选择市场价");
            return false;
        }
        if (stepModel.PriceSyncSetting.MktPriceSetting && !stepModel.PriceSyncSetting.MktPriceSetting.Value) {
            Alert("请选择销售价");
            return false;
        }
        if (stepModel.PriceSyncSetting.CostPriceSetting && !stepModel.PriceSyncSetting.CostPriceSetting.Value) {
            Alert("请选择成本价");
            return false;
        }
        if (!stepModel.OrderInfoSetting.OrderTypeSetting.Value) {
            Alert("请选择同步订单类型");
            return false;
        }
        // if (!stepModel.OrderInfoSetting.Btype || !stepModel.OrderInfoSetting.Btype.BFullName) {
        //     Alert("往来单位为空");
        //     return false;
        // }
        // if (!stepModel.OrderInfoSetting.Etype || !stepModel.OrderInfoSetting.Etype.EFullName) {
        //     Alert("职员为空");
        //     return false;
        // }
        // if (!stepModel.OrderInfoSetting.Ktype || !stepModel.OrderInfoSetting.Ktype.KFullName) {
        //     Alert("仓库为空");
        //     return false;
        // }
        // if (!stepModel.OrderInfoSetting.Dtype || !stepModel.OrderInfoSetting.Dtype.DFullName) {
        //     Alert("部门为空");
        //     return false;
        // }
        return true;
    }

    // 更新完成引导标识
    updateImport = () => {
        let param = {
            mth: "SaveGuideCompleteState",
            state: 1
        }
        API.postData(param, "/UI/Handler/SetUp.ashx", () => { });
    }

    //完成
    finished = () => {
        this.props.finished && this.props.finished();
    }

    render() {
        let { finished, stepIndex, stepStr, stepProp, stepModel, customerShadow, warehouseShadow,dtypeShadow, userShadow, stypeShadow,
            customerTipShadow, customerHalfShadow, goodsTipShadow, goodsHalfShadow, rule } = this.state;
        return (
            <div className="guide-system">
                {finished ?
                    <div className="success">
                        <img src={successImg} alt="" />
                        <p className="txt">全程通设置完成，数据正在同步，您可以点击下一步进入全程通主页面</p>
                        <div className="btn-group">
                            <Button type="primary" size="large" onClick={this.finished.bind(this)}>完成</Button>
                        </div>
                    </div>
                    :
                    <div className="step-cont box box-center">
                        <div className="step box box-vertical">
                            <div className="inner">
                                <p className="title">基础设置</p>
                                <ul>
                                    {
                                        stepStr.map((item: any, index: number) => {
                                            return (
                                                stepModel[stepProp[index]] && <li key={index} className={index === stepIndex ? 'active' : ''}>{index + 1}、{item}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="box1 cont box box-vertical">
                            {stepIndex === 0 && stepModel[stepProp[0]] &&
                                <div className="form">
                                    <p className="title">请问您是需要同步所有商品还是部分商品？</p>
                                    {this.chooseSec(stepProp[0], stepModel[stepProp[0]])}
                                </div>
                            }
                            {stepIndex === 1 && stepModel[stepProp[1]] &&
                                <div className="form">
                                    {stepModel[stepProp[1]].MktPriceSetting && <div className="form-item">
                                        <p className="title">请问您是需要将管家婆的哪一个价格同步到商城作为市场价？</p>
                                        <Message type="warning" title="市场价是未注册用户/访客看到的价格" />
                                        {this.selectSec(stepModel[stepProp[1]].MktPriceSetting.List, stepModel[stepProp[1]].MktPriceSetting.Value, stepProp[1], "MktPriceSetting")}
                                    </div>}
                                    {stepModel[stepProp[1]].SalePriceSetting && <div className="form-item">
                                        <p className="title">请问您是需要将管家婆的哪一个价格同步到商城作为销售价？</p>
                                        <Message type="warning" title="销售价是新注册用户看到的价格" />
                                        {this.selectSec(stepModel[stepProp[1]].SalePriceSetting.List, stepModel[stepProp[1]].SalePriceSetting.Value, stepProp[1], "SalePriceSetting")}
                                    </div>}
                                    {stepModel[stepProp[1]].CostPriceSetting && <div className="form-item">
                                        <p className="title">请问您是需要将管家婆的哪一个价格同步到商城作为成本价？</p>
                                        <Message type="warning" title="" />
                                        {this.selectSec(stepModel[stepProp[1]].CostPriceSetting.List, stepModel[stepProp[1]].CostPriceSetting.Value, stepProp[1], "CostPriceSetting")}
                                    </div>}
                                </div>
                            }
                            {stepIndex === 2 && stepModel[stepProp[2]] &&
                                <div className="form">
                                    <p className="title">请问您是需要同步所有仓库？</p>
                                    <ul className="choose-list choose-list-sm">
                                        {stepModel[stepProp[2]].StorageSyncSetting.List.map((item: any, index: number) =>
                                            <li key={item.Key} className={item.Key === stepModel[stepProp[2]].StorageSyncSetting.Value ? 'active' : ''} onClick={this.changeSelect.bind(this, stepProp[2], "StorageSyncSetting", item.Key)}>
                                                <p>{item.Value}</p>
                                            </li>
                                        )}
                                    </ul>
                                    <p className="title">请问您是需要同步库存？</p>
                                    <ul className="choose-list choose-list-sm">
                                        {stepModel[stepProp[2]].StockSyncSetting.List.map((item: any, index: number) =>
                                            <li key={item.Key} className={item.Key === stepModel[stepProp[2]].StockSyncSetting.Value ? 'active' : ''} onClick={this.changeSelect.bind(this, stepProp[2], "StockSyncSetting", item.Key)}>
                                                <p>{item.Value}</p>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            }
                            {stepIndex === 3 && stepModel[stepProp[3]] &&
                                <div className="form">
                                    <p className="title">请问您是需要同步所有往来单位还是部分往来单位？</p>
                                    {this.chooseSec(stepProp[3], stepModel[stepProp[3]])}
                                </div>
                            }
                            {stepIndex === 4 && stepModel[stepProp[4]] &&
                                <div className="form box1">
                                    <div className="form-item">
                                        <p className="title">请问您是需要同步为销售订单草稿还是销售出库单草稿？</p>
                                        <div className="box box-center">
                                            <RadioGroup name="OrderTypeSetting" value={stepModel[stepProp[4]].OrderTypeSetting.Value} onChange={this.changeRadio.bind(this, stepProp[4], "OrderTypeSetting")}>
                                                {stepModel[stepProp[4]].OrderTypeSetting.List.map((item: any, index: number) =>
                                                    <Radio value={item.Key}>{item.Value}</Radio>
                                                )}
                                            </RadioGroup>
                                        </div>
                                    </div>
                                    {/* <div className="form-item">
                                        <p className="title">当订单同步到管家婆找不到对应的往来单位资料时，我们需要一个备用的往来单位信息，请选择备用往来单位：</p>
                                        <ClickInput className="w-sm" value={stepModel[stepProp[4]].Btype?.BFullName ?? ""} onClick={() => this.setState({ customerShadow: true })} />
                                    </div>
                                    <div className="form-item">
                                        <p className="title">请选择管家婆经常对订单进行过账处理的职员：</p>
                                        <ClickInput className="w-sm" value={stepModel[stepProp[4]].Etype?.EFullName ?? ""} onClick={() => this.setState({ userShadow: true })} />
                                    </div>
                                    <div className="form-item">
                                        <p className="title">请选择管家婆使用的主仓库：</p>
                                        <ClickInput className="w-sm" value={stepModel[stepProp[4]].Ktype?.KFullName ?? ""} onClick={() => this.setState({ warehouseShadow: true })} />
                                    </div>
                                    <div className="form-item">
                                        <p className="title">请选择管家婆使用的部门：</p>
                                        <ClickInput className="w-sm" value={stepModel[stepProp[4]].Dtype?.DFullName?? ""} onClick={() => this.setState({ dtypeShadow: true })} />
                                    </div> */}
                                </div>
                            }
                            <div className="btn-group">
                                {stepIndex !== 0 && <Button type="warning" onClick={this.prevStep.bind(this)}>上一步</Button>}
                                <Button type="primary" onClick={this.nextStep.bind(this)}>下一步</Button>
                            </div>
                        </div>
                    </div>
                }
                {/* 往来单位弹层 */}
                <CustomerShadow
                    isOpen={customerShadow}
                    onClose={() => this.setState({ customerShadow: false })}
                    onConfirm={(choosed: any) => {
                        stepModel.OrderInfoSetting.Btype = choosed;
                        this.setState({ stepModel: stepModel, customerShadow: false });
                    }}
                />
                {/* 仓库弹层 */}
                <WareHouseShadow
                    isOpen={warehouseShadow}
                    onClose={() => this.setState({ warehouseShadow: false })}
                    onConfirm={(choosed: any) => {
                        stepModel.OrderInfoSetting.Ktype = choosed;
                        this.setState({ stepModel: stepModel, warehouseShadow: false });
                    }}
                />
                {/* 部门弹层 */}
                <DepartmentShadow
                    isOpen={dtypeShadow}
                    stypeId={stepModel.OrderInfoSetting?.Dtype?.DtypeID}
                    onClose={() => this.setState({ dtypeShadow: false })}
                    onConfirm={(choosed: any) => {
                        stepModel.OrderInfoSetting.Dtype = choosed;
                        this.setState({ stepModel: stepModel, dtypeShadow: false });
                    }}
                />
                {/* 经手人弹层 */}
                <UserShadow
                    title="选择职员"
                    isOpen={userShadow}
                    onClose={() => this.setState({ userShadow: false })}
                    onConfirm={(choosed: any) => {
                        stepModel.OrderInfoSetting.Etype = choosed;
                        this.setState({ stepModel: stepModel, userShadow: false });
                    }}
                />
                {/* 往来单位同步提示 */}
                <Shadow
                    title="往来单位同步信息提示"
                    isOpen={customerTipShadow}
                    onClose={() => this.setState({ customerTipShadow: false, customerHalfShadow: stepModel.CustomerSyncSetting?.Value == "2" })}
                    footer={
                        <Button type="primary" onClick={() => this.setState({ customerTipShadow: false, customerHalfShadow: stepModel.CustomerSyncSetting?.Value == "2" })}>我知道了</Button>
                    }
                >
                    <div className="tips-pop">
                        <p>系统将自动的把客户档案上传到商城；</p>
                        <p>以下是信息上传规则：</p>
                        <ul className="rule-list">
                            {rule.map((item: any, index: number) =>
                                <li key={index}>{index + 1}、{item}</li>
                            )}
                        </ul>
                    </div>
                </Shadow>
                {/* 往来单位选择弹层 */}
                <CustomerHalfShadow isOpen={customerHalfShadow} onClose={() => this.setState({ customerHalfShadow: false })} />
                {/* 商品同步提示 */}
                <Shadow
                    title="是否有多规格商品"
                    isOpen={goodsTipShadow}
                    onClose={() => this.setState({ goodsTipShadow: false, goodsHalfShadow: stepModel.ProductSyncSetting?.Value == "2" })}
                    footer={
                        <div>
                            <Button type="primary" onClick={() => {
                                this.setState({ goodsTipShadow: false });
                                this.setState((preState: any) => {
                                    let stepModel = preState.stepModel;
                                    stepModel.ProductSyncSetting.Value = '0';
                                    return { stepModel: stepModel }
                                });
                            }}>有多规格</Button>
                            {stepModel.ProductSyncSetting?.Value == "2" ?
                                <Button type="primary" onClick={() => this.setState({ goodsTipShadow: false, goodsHalfShadow: true })}>选择单规格</Button>
                                :
                                <Button type="primary" onClick={() => this.setState({ goodsTipShadow: false })}>没有多规格</Button>
                            }
                        </div>
                    }
                >
                    <div className="tips-pop">
                        <p>确定多规格商品已处理完毕或者没有多规格商品？</p>
                        <p>如有多规格商品可按照下面两种方式进行创建：</p>
                        <p>第一种创建方式1：</p>
                        <p>请往菜单“基础信息对应-{'>'}上传商品多规格”进行设置</p>
                        <p>第二种创建方式：</p>
                        <p>1、请前往商城添加</p>
                        <p>2、并保持商城的货品与Erp的商品编号一致</p>
                        <p>3、设置完毕后在进行“商品对应”设置</p>
                    </div>
                </Shadow>
                {/* 商品选择弹层 */}
                <GoodsHalfShadow isOpen={goodsHalfShadow} onClose={() => this.setState({ goodsHalfShadow: false })} />
            </div>
        )
    }
}