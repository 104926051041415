//confirm框
import * as React from 'react';
import * as ReactDom from 'react-dom';
import { Alert, Button } from 'uiw';
import './index.scss';

export default function Confirm(
    title: string,                        //标题
    content: string | React.ReactNode,      //内容
    button = [                             //按钮
        { name: '取消', type: 'light', onClick: () => { } },
        { name: '确定', type: 'primary', onClick: () => { } }
    ],
    duration?: number           //自动关闭时长
) {
    let closed = false;
    let timer: any;

    const div = document.createElement('div');
    document.body.appendChild(div);

    function close() {
        ReactDom.unmountComponentAtNode(div);
        if (div && div.parentNode) {
            div.parentNode.removeChild(div);
        }
        clearTimeout(timer);
    }
    if (duration && duration !== 0) {
        timer = setTimeout(() => {
            close();
        }, duration);
    }

    let footer = () => {
        return (
            <div className="confirm-footer">
                {
                    button.map((item: any, index: number) => {
                        const orginPress = item.onClick || function () { };
                        item.onClick = () => {
                            if (closed) {
                                return;
                            }

                            const res = orginPress() as any;
                            if (res && res.then) {
                                res.then(() => {
                                    closed = true;
                                    close();
                                }).catch(() => { })
                            } else {
                                closed = true;
                                close();
                            }
                        }
                        return (
                            <Button onClick={item.onClick} key={index} type={item.type}>{item.name}</Button>
                        )
                    })
                }
            </div>
        )
    }

    ReactDom.render(
        <Alert
            className="custom-confirm"
            useButton={false}
            title={title}
            isOpen={true}
            onClosed={close}
        >
            {
                typeof (content) == "string" ?
                    <div className="confirm-content" dangerouslySetInnerHTML={{ __html: '' + content?.toString() }}></div>
                    : <div className="confirm-content">{content}</div>
            }
            {footer()}
        </Alert>,
        div
    );
    return {
        closed,
    };
}