//弹层
import * as React from 'react';
import classnames from 'classnames';
import {Overlay} from 'uiw';
import './index.scss';

export default class Shadow extends React.Component<any,any>{
    static defaultProps = {
        isOpen:false,
        title:'弹层',
        discrib:'',
        width:600,
        height:'auto'
    };
    //点击弹层回调
    onClose = () => {
        this.props.onClose && this.props.onClose();
    }
    render(){
        let {isOpen,className,width,height,title,discrib,children,footer} = this.props;
        return(
            <Overlay 
                isOpen = {isOpen}
                onClose={this.onClose.bind(this)}
            >
                <div style={{width:width}} className = {classnames("custom-shadow",className)}>
                    <div className="custom-shadow-title box box-center">
                        <p className="title box1 overflow">{title}
                            {discrib!='' &&
                                <span className="discrib" >{discrib}</span>
                            }
                        </p>
                        <a className="shut" onClick={this.onClose.bind(this)}>×</a>
                    </div>
                    <div className="custom-shadow-cont" style={{maxHeight:height}}>
                        {children}
                    </div>
                    <div className="custom-shadow-footer">
                        {footer}
                    </div>
                </div>
            </Overlay>
        )
    }
}