//商品页面的商品对应关系
import * as React from 'react';
import { Table } from 'uiw';
import { Shadow } from '@/components';
import EcShopPayType from '@/widget/shadow/atype/EcShopPayType';
import API from '@/utils/http';

export default class SetPayTypeRelations extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            relationData: this.relationData(null),
            showSetProductByEcShopSkuId: false,
            currentSelectedObj: {},
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isOpen == true) {
            this.getProductRelations();
        }
    }


    //往来单位关系数据结构
    relationData(payList: any) {
        let data = {
            columns: [
                { title: 'ERP编号', width: 200, key: 'aUserCode' },
                { title: 'ERP名称', key: 'aFullname' },
                {
                    title: '操作',
                    key: 'edit',
                    width: 150,
                    render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => {

                        return (
                            <div>
                                <a className="table-single-btn" onClick={() => this.onSettting(rowData)}>设置</a>
                                <a className="table-single-btn" onClick={() => this.onCancel(rowData)}>取消</a>
                            </div>
                        )
                    }
                },
                {
                    title: '商城编号',
                    width: 200,
                    key: 'EcShopANo',
                },
                {
                    title: '商城名称',
                    key: 'EcShopAName',

                },
            ],
            data: payList,
        };

        return data;
    }

    // 点击设置
    onSettting = (selectedObj: any) => {
        this.setState({
            currentSelectedObj: selectedObj,
            showSetProductByEcShopSkuId: true
        });
    }

    onCancel = (rowData: any) => {

        if (!rowData.EcShopAID || rowData.EcShopAID == '')
            return;
        //
        let that = this;
        let param = {
            mth: "deleteAtypeRelations",
            EcShopAID: rowData.EcShopAID
        };
        API.postData(param, '/UI/abill/ajax.ashx', (res: any) => {
            that.getProductRelations();
        });

    }



    //获取往来单位对应关系数据
    getProductRelations = () => {
        let that = this;
        let { selectedObj } = that.props;
        let param = {
            mth: "payTypeRelation"
        };
        API.postData(param, '/UI/abill/ajax.ashx', (res: any) => {
            let relationData = that.relationData(res.data.list);
            that.setState({
                relationData: relationData
            });
        });
    }


    //关闭
    onClose = () => {
        this.props.onClose && this.props.onClose();
    }


    render() {
        let { isOpen, selectedObj } = this.props;
        let { currentSelectedObj } = this.state;
        let { showSetProductByEcShopSkuId, relationData } = this.state;

        return (
            <Shadow
                title="设置收款账户对应关系"
                isOpen={isOpen}
                width={800}
                onClose={this.onClose.bind(this)}
            >
                <div className="shadow-search box box-center">
                    <div className="box box-center">
                        <p>设置收款账户对应关系</p>
                    </div>
                </div>
                <div className="martop" style={{ height: 400, overflow: 'auto' }}>
                    <Table
                        className="custom-table custom-table-hasline"
                        columns={relationData.columns}
                        data={relationData.data}
                    />
                </div>
                {/* 对应关系弹层 */}
                <EcShopPayType
                    isOpen={showSetProductByEcShopSkuId}
                    selectedObj={currentSelectedObj}
                    onClose={() => { this.setState({ showSetProductByEcShopSkuId: false }) }}
                    onConfirm={(choosed: any) => {
                        if (choosed) {
                            this.setState({ showSetProductByEcShopSkuId: false })
                            this.getProductRelations();
                        }
                    }}
                />
            </Shadow>
        )
    }


}