//选择部分往来单位弹层
import * as React from 'react';
import { Shadow, Alert, Pagi } from '@/components';
import { Button, Input, Checkbox, Pagination, Row, Col, Table } from 'uiw';
import './index.scss';
import API from '@/utils/http';

export default class CustomerHalfShadow extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            leftTable: {
                checked: [],
                keyword: '',
                parentId: '',
                pathList: [],
                relation: this.getUnSelectRelation(1, 15, [], 0),
                columns: [
                    {
                        title: (data: any, rowNum: number, colNum: number) => {
                            const indeterminate = this.state.leftTable.checked.length > 0 && this.state.leftTable.relation.list.length !== this.state.leftTable.checked.length;
                            const checked = this.state.leftTable.relation.list.length > 0 && this.state.leftTable.relation.list.length === this.state.leftTable.checked.length;
                            return (
                                <Checkbox
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    onClick={(evn: any) => {
                                        let checked = this.state.leftTable.relation.list.map((item: any) => item.BtypeID);
                                        if (evn.target.checked) {
                                            this.addQctSyncCustomer(checked);
                                        } else {
                                            checked = [];
                                        }
                                        let leftTable = this.state.leftTable;
                                        leftTable.checked = checked;
                                        this.setState({ leftTable: leftTable });
                                    }}
                                />
                            );
                        },
                        key: 'checked',
                        width: 40,
                        render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => {
                            return (
                                <div>
                                    <Checkbox checked={rowData.checked} onClick={this.onClickCheckedItem.bind(this, 'leftTable', rowData)} />
                                    {rowData.IsHasChild ? "..." : ""}
                                </div>
                            );
                        }
                    },
                    {
                        title: '客户编号',
                        key: 'BUserCode',
                        render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => {
                            if (rowData.IsHasChild) {
                                return (
                                    <div onDoubleClick={this.onShowChild.bind(this, rowData.BtypeID, rowData.BFullName)}>{text}</div>
                                )
                            } else {
                                return (
                                    <div>{text}</div>
                                )
                            }
                        }
                    },
                    {
                        title: '客户名称',
                        key: 'BFullName',
                        render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => {
                            if (rowData.IsHasChild) {
                                return (
                                    <div onDoubleClick={this.onShowChild.bind(this, rowData.BtypeID, rowData.BFullName)}>{text}</div>
                                )
                            } else {
                                return (
                                    <div>{text}</div>
                                )
                            }
                        }
                    }
                ],
            },
            rightTable: {
                checked: [],
                keyword: '',
                relation: this.getSelectRelation(1, 15, [], 0),
                columns: [
                    {
                        title: (data: any, rowNum: number, colNum: number) => {
                            const indeterminate = this.state.rightTable.checked.length > 0 && this.state.rightTable.relation.list.length !== this.state.rightTable.checked.length;
                            const checked = this.state.rightTable.relation.list.length > 0 && this.state.rightTable.relation.list.length === this.state.rightTable.checked.length;
                            return (
                                <Checkbox
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    onClick={(evn: any) => {
                                        let checked = this.state.rightTable.relation.list.map((item: any) => item.BtypeID);
                                        if (evn.target.checked) {
                                            this.deleteQctSyncCustomer(checked);
                                        } else {
                                            checked = [];
                                        }
                                        let rightTable = this.state.rightTable;
                                        rightTable.checked = checked;
                                        this.setState({ rightTable: rightTable });
                                    }}
                                />
                            );
                        },
                        key: 'checked',
                        width: 40,
                        render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => {
                            return (
                                <Checkbox checked={rowData.checked} onClick={this.onClickCheckedItem.bind(this, 'rightTable', rowData)} />
                            );
                        }
                    },
                    { title: '客户编号', key: 'BUserCode' },
                    { title: '客户名称', key: 'BFullName' }
                ],
            },
        }
    }


    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isOpen == true) {
            this.getData();
        }
    }

    getData = () => {
        this.getUnSelectList('', 1, 15);
        this.getSelectList(1, 15);
    }

    // 待选择对象
    getUnSelectRelation = (pageIndex: number, pageSize: number, list: any, total: number) => {
        let obj = {
            count: total,
            pageIndex: pageIndex,
            pageSize: pageSize,
            list: list
        };
        return obj;
    }

    // 已选择对象
    getSelectRelation = (pageIndex: number, pageSize: number, list: any, total: number) => {
        let obj = {
            count: total,
            pageIndex: pageIndex,
            pageSize: pageSize,
            list: list
        };
        return obj;
    }

    // 待选择列表
    getUnSelectList = (keyword: string, pageIndex: number, pageSize: number) => {
        let that = this;
        let { leftTable } = this.state;
        let param = {
            mth: 'GetSelectCustomerList',
            pageIndex: pageIndex,
            pageSize: pageSize,
            keywrod: keyword,
            parentId: leftTable.parentId,
        };
        API.postData(param, "/UI/products/ajax.ashx", (res: any) => {
            if (res.code) {
                Alert(res.msg);
                return;
            }
            leftTable.relation = that.getUnSelectRelation(pageIndex, res.pagesize, res.list, res.count);
            that.setState({
                leftTable: leftTable
            });
        });
    }

    // 已选择列表
    getSelectList = (pageIndex: number, pageSize: number) => {
        let that = this;
        let { rightTable } = this.state;
        let param = {
            mth: 'GetSelectCustomerListChecked',
            pageIndex: pageIndex,
            pageSize: pageSize,
            keywrod: rightTable.keyword
        };
        API.postData(param, "/UI/products/ajax.ashx", (res: any) => {
            if (res.code) {
                Alert(res.msg);
                return;
            }
            rightTable.relation = that.getSelectRelation(pageIndex, res.pagesize, res.list, res.count);
            that.setState({
                rightTable: rightTable
            });
        });
    }

    // 选中后，直接调用接口
    onClickCheckedItem = (type: string, rowData: any, e: any) => {
        const isChecked = e.target.checked;
        let pid = [rowData.BtypeID];
        if (type == 'leftTable' && isChecked) {
            this.addQctSyncCustomer(pid);
        }
        if (type == 'rightTable' && isChecked) {
            this.deleteQctSyncCustomer(pid);
        }
        e.target.checked = false;
    }

    // 新增需要部分同步的商品
    addQctSyncCustomer = (pidList: any) => {
        let param = {
            mth: 'AddQctSyncCustomer',
            pid: pidList.join(",")
        }
        API.postData(param, "/UI/products/ajax.ashx", (res: any) => {
            this.clearData();
            this.getData();
        })
    }

    // 删除需要部分同步的商品
    deleteQctSyncCustomer = (pidList: any) => {
        let param = {
            mth: 'DeleteQctSyncCustomer',
            pid: pidList.join(",")
        }
        API.postData(param, "/UI/products/ajax.ashx", (res: any) => {
            this.clearData();
            this.getData();
        })
    }

    // 左表搜索
    onSearchLeft = (e: any) => {
        let val = e.target.value;
        this.setState((preState: any) => {
            let leftTable = preState.leftTable;
            leftTable.keyword = val;
            return { leftTable: leftTable }
        })
    }

    // 右表搜索
    onSearchRight = (e: any) => {
        let val = e.target.value;
        this.setState((preState: any) => {
            let rightTable = preState.rightTable;
            rightTable.keyword = val;
            return { rightTable: rightTable }
        })
    }

    // 双击显示下一级
    onShowChild = (id: any, name: any) => {
        let that = this;
        that.setState((preSate: any) => {
            let leftTable = preSate.leftTable;
            if (leftTable.pathList.length == 0) {
                leftTable.pathList.push({ id: "", name: "根目录" });
            }
            let index = leftTable.pathList.findIndex((i: any) => i.id == id);
            if (index > -1) {
                leftTable.pathList.splice(index + 1, leftTable.pathList.length - index);
            } else {
                leftTable.pathList.push({ id: id, name: name });
            }
            leftTable.parentId = id;
            return { leftTable: leftTable }
        }, () => {
            that.getUnSelectList('', 1, that.state.leftTable.relation.pageSize);
        })
    }

    // 清除记忆
    clearData = (isClose: boolean = false) => {
        if (isClose) {
            this.state.leftTable.parentId = '';
            this.state.leftTable.pathList = [];
        }

        this.state.leftTable.checked = [];
        this.state.leftTable.keyword = '';

        this.state.rightTable.checked = [];
        this.state.rightTable.keyword = '';
    }

    //关闭层
    onClose = () => {
        this.clearData(true);
        this.props.onClose && this.props.onClose();
    }



    render() {
        let { isOpen } = this.props;
        let { leftTable, rightTable } = this.state;
        const leftSource = leftTable.relation.list.map((item: any, idx: number) => {
            const isChecked = leftTable.checked.indexOf(idx) > -1;
            return { checked: isChecked, ...item };
        });
        const rightSource = rightTable.relation.list.map((item: any, idx: number) => {
            const isChecked = rightTable.checked.indexOf(idx) > -1;
            return { checked: isChecked, ...item };
        });
        return (
            <Shadow
                title={"选择需要同步的往来单位"}
                width={900}
                isOpen={isOpen}
                onClose={this.onClose.bind(this)}
                className="uni-half-pop"
                footer={
                    <Button type="primary" onClick={this.onClose.bind(this)}>确定</Button>
                }
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <div className="unit-half-item">
                            <div className="mt box box-center">
                                <p className="title box1">待选择</p>
                                <div className="custom-search">
                                    <Input placeholder="请输入往来单位名称" value={leftTable.keyword} onChange={this.onSearchLeft} />
                                    <Button type="primary" onClick={() => this.getUnSelectList(leftTable.keyword, 1, leftTable.relation.pageSize)}>搜索</Button>
                                </div>
                            </div>
                            <div className="mt box box-center">
                                {leftTable.pathList.map((i: any) => <a onClick={() => { this.onShowChild(i.id, i.name) }}>{i.name}/</a>)}
                            </div>
                            <div className="mc">
                                <Table className="custom-table" columns={leftTable.columns} data={leftSource} />
                            </div>
                            <div className="mf">
                                <Pagination alignment="right"
                                    current={leftTable.relation.pageIndex}
                                    pageSize={leftTable.relation.pageSize}
                                    total={leftTable.relation.count}
                                    divider
                                    onChange={(current: number, total: number, pageSize: number) => {
                                        this.getUnSelectList(leftTable.keyword, current, pageSize);
                                    }} />
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="unit-half-item">
                            <div className="mt box box-center">
                                <p className="title box1">已选择</p>
                                <div className="custom-search">
                                    <Input placeholder="请输入往来单位名称" value={rightTable.keyword} onChange={this.onSearchRight} />
                                    <Button type="primary" onClick={() => this.getSelectList(1, rightTable.relation.pageSize)}>搜索</Button>
                                </div>
                            </div>
                            <div className="mc">
                                <Table className="custom-table" columns={rightTable.columns} data={rightSource} />
                            </div>
                            <div className="mf">
                                <Pagination alignment="right"
                                    current={rightTable.relation.pageIndex}
                                    pageSize={rightTable.relation.pageSize}
                                    total={rightTable.relation.count}
                                    divider
                                    onChange={(current: number, total: number, pageSize: number) => {
                                        this.getSelectList(current, pageSize);
                                    }} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Shadow>
        )
    }
}

