//框架主体
import * as React from 'react';
import { Switch, Route } from 'react-router-dom'
import { default as pageRouter} from '@/pages/router';

export default class IframeContent extends React.Component<any,any>{
    render(){
        return(
            <Switch>
                {
                    pageRouter.map((item:any,index:number) => 
                        <Route 
                            key={index}
                            exact={item.exact}
                            path={item.path} 
                            component={item.component}
                        />
                    )
                }
            </Switch>
        )
    }
}