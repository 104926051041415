//可点击选择的输入框
import * as React from 'react';
import classnames from 'classnames';
import { Input, Button } from 'uiw';
import './index.scss'

export default class ClickInput extends React.Component<any, any>{
    static defaultProps = {
        disabled: false,
        hasClear: false    //是否可清理
    };
    onClick = () => {
        this.props.onClick && this.props.onClick();
    }
    //清理选中的
    onClear = () => {
        this.props.onClear && this.props.onClear();
    }
    onChange = (e: any) => {
        this.props.onChange && this.props.onChange(e);
    }
    render() {
        let { placeholder, className, disabled, value, hasClear, title } = this.props;
        return (

            <Input
                title={title}
                disabled={disabled}
                className={classnames("custom-click-input", className)}
                placeholder={placeholder}
                value={value}
                onChange={this.onChange.bind(this)}
                addonAfter={
                    <React.Fragment>
                        {(hasClear && value != '') &&
                            <Button icon="close" size="small" basic type="light" onClick={this.onClear.bind(this)} />
                        }
                        <Button icon="menu" size="small" basic type="light" onClick={this.onClick.bind(this)} />
                    </React.Fragment>
                }
            />
        )
    }
}