//选择部分商品弹层
import * as React from 'react';
import { Shadow, Alert, Pagi } from '@/components';
import { Button, Input, Checkbox, Pagination, Row, Col, Table } from 'uiw';
import './index.scss';
import API from '@/utils/http';

export default class GoodsHalfShadow extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            leftTable: {
                checked: [],
                keyword: '',
                parentId: '',
                pathList: [],
                relation: this.getUnSelectRelation(1, 15, [], 0),
                columns: [
                    {
                        title: (data: any, rowNum: number, colNum: number) => {
                            const indeterminate = this.state.leftTable.checked.length > 0 && this.state.leftTable.relation.list.length !== this.state.leftTable.checked.length;
                            const checked = this.state.leftTable.relation.list.length > 0 && this.state.leftTable.relation.list.length === this.state.leftTable.checked.length;
                            return (
                                <Checkbox
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    onClick={(evn: any) => {
                                        let checked = this.state.leftTable.relation.list.map((item: any) => item.PtypeID);
                                        if (evn.target.checked) {
                                            this.addQctSyncProduct(checked);
                                        } else {
                                            checked = [];
                                        }
                                        let leftTable = this.state.leftTable;
                                        leftTable.checked = checked;
                                        this.setState({ leftTable: leftTable });
                                    }}
                                />
                            );
                        },
                        key: 'checked',
                        width: 40,
                        render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => {
                            return (
                                <div>
                                    <Checkbox checked={rowData.checked} onClick={this.onClickCheckedItem.bind(this, 'leftTable', rowData)} />
                                    {rowData.IsHasChild ? "..." : ""}
                                </div>
                            );
                        }
                    },
                    {
                        title: '货品编号',
                        key: 'PUserCode',
                        render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => {
                            if (rowData.IsHasChild) {
                                return (
                                    <div onDoubleClick={this.onShowChild.bind(this, rowData.PtypeID, rowData.PFullName)}>{text}</div>
                                )
                            } else {
                                return (
                                    <div>{text}</div>
                                )
                            }
                        }
                    },
                    {
                        title: '货品名称',
                        key: 'PFullName',
                        render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => {
                            if (rowData.IsHasChild) {
                                return (
                                    <div onDoubleClick={this.onShowChild.bind(this, rowData.PtypeID, rowData.PFullName)}>{text}</div>
                                )
                            } else {
                                return (
                                    <div>{text}</div>
                                )
                            }
                        }
                    }
                ],
            },
            rightTable: {
                checked: [],
                keyword: '',
                relation: this.getSelectRelation(1, 15, [], 0),
                columns: [
                    {
                        title: (data: any, rowNum: number, colNum: number) => {
                            const indeterminate = this.state.rightTable.checked.length > 0 && this.state.rightTable.relation.list.length !== this.state.rightTable.checked.length;
                            const checked = this.state.rightTable.relation.list.length > 0 && this.state.rightTable.relation.list.length === this.state.rightTable.checked.length;
                            return (
                                <Checkbox
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    onClick={(evn: any) => {
                                        let checked = this.state.rightTable.relation.list.map((item: any) => item.PtypeID);
                                        if (evn.target.checked) {
                                            this.deleteQctSyncProduct(checked);
                                        } else {
                                            checked = [];
                                        }
                                        let rightTable = this.state.rightTable;
                                        rightTable.checked = checked;
                                        this.setState({ rightTable: rightTable });
                                    }}
                                />
                            );
                        },
                        key: 'checked',
                        width: 40,
                        render: (text: any, key: any, rowData: any, rowNumber: number, columnNumber: number) => {
                            return (
                                <Checkbox checked={rowData.checked} onClick={this.onClickCheckedItem.bind(this, 'rightTable', rowData)} />
                            );
                        }
                    },
                    { title: '货品编号', key: 'PUserCode' },
                    { title: '货品名称', key: 'PFullName' }
                ],
            },
        }
    }


    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isOpen == true) {
            this.getData();
        }
    }

    getData = () => {
        this.getUnSelectList(1, 15);
        this.getSelectList(1, 15);
    }

    // 待选择对象
    getUnSelectRelation = (pageIndex: number, pageSize: number, list: any, total: number) => {
        let obj = {
            count: total,
            pageIndex: pageIndex,
            pageSize: pageSize,
            list: list
        };
        return obj;
    }

    // 已选择对象
    getSelectRelation = (pageIndex: number, pageSize: number, list: any, total: number) => {
        let obj = {
            count: total,
            pageIndex: pageIndex,
            pageSize: pageSize,
            list: list
        };
        return obj;
    }

    // 待选择列表
    getUnSelectList = (pageIndex: number, pageSize: number) => {
        let that = this;
        let { leftTable } = this.state;
        let param = {
            mth: 'GetSelectProductList',
            pageIndex: pageIndex,
            pageSize: pageSize,
            keywrod: leftTable.keyword,
            parentId: leftTable.parentId,
        };
        API.postData(param, "/UI/products/ajax.ashx", (res: any) => {
            if (res.code) {
                Alert(res.msg);
                return;
            }
            leftTable.relation = that.getUnSelectRelation(pageIndex, res.pagesize, res.list, res.count);
            that.setState({
                leftTable: leftTable
            });
        });
    }

    // 已选择列表
    getSelectList = (pageIndex: number, pageSize: number) => {
        let that = this;
        let { rightTable } = this.state;
        let param = {
            mth: 'GetSelectProductListChecked',
            pageIndex: pageIndex,
            pageSize: pageSize,
            keywrod: rightTable.keyword
        };
        API.postData(param, "/UI/products/ajax.ashx", (res: any) => {
            if (res.code) {
                Alert(res.msg);
                return;
            }
            rightTable.relation = that.getSelectRelation(pageIndex, res.pagesize, res.list, res.count);
            that.setState({
                rightTable: rightTable
            });
        });
    }

    // 选中
    // onClickCheckedItem = (type: string, rowData: any, e: any) => {
    //     const isChecked = e.target.checked;
    //     let pid = [rowData.PtypeID];
    //     this.setState((preState: any) => {
    //         let checked = preState[type].checked;
    //         if (isChecked) {
    //             // 添加到选中数组中
    //             checked.push(pid)
    //             checked = checked.sort((a: number, b: number) => a - b);
    //         } else {
    //             // 删除选中项
    //             checked.splice(checked.indexOf(pid), 1);
    //         }
    //         return { [type]: preState[type] };
    //     })
    // }

    // 选中后，直接调用接口
    onClickCheckedItem = (type: string, rowData: any, e: any) => {
        const isChecked = e.target.checked;
        let pid = [rowData.PtypeID];
        if (type == 'leftTable' && isChecked) {
            this.addQctSyncProduct(pid);
        }
        if (type == 'rightTable' && isChecked) {
            this.deleteQctSyncProduct(pid);
        }
        e.target.checked = false;
    }

    // 新增需要部分同步的商品
    addQctSyncProduct = (pidList: any) => {
        let param = {
            mth: 'AddQctSyncProduct',
            pid: pidList.join(",")
        }
        API.postData(param, "/UI/products/ajax.ashx", (res: any) => {
            //this.clearData();
            this.getData();
        })
    }

    // 删除需要部分同步的商品
    deleteQctSyncProduct = (pidList: any) => {
        let param = {
            mth: 'DeleteQctSyncProduct',
            pid: pidList.join(",")
        }
        API.postData(param, "/UI/products/ajax.ashx", (res: any) => {
            //this.clearData();
            this.getData();
        })
    }

    // 左表搜索
    onSearchLeft = (e: any) => {
        let val = e.target.value;
        this.setState((preState: any) => {
            let leftTable = preState.leftTable;
            leftTable.keyword = val;
            return { leftTable: leftTable }
        })
    }

    // 右表搜索
    onSearchRight = (e: any) => {
        let val = e.target.value;
        this.setState((preState: any) => {
            let rightTable = preState.rightTable;
            rightTable.keyword = val;
            return { rightTable: rightTable }
        })
    }

    // 双击显示下一级
    onShowChild = (id: any, name: any) => {
        let that = this;
        that.setState((preSate: any) => {
            let leftTable = preSate.leftTable;
            if (leftTable.pathList.length == 0) {
                leftTable.pathList.push({ id: "", name: "根目录" });
            }
            let index = leftTable.pathList.findIndex((i: any) => i.id == id);
            if (index > -1) {
                leftTable.pathList.splice(index + 1, leftTable.pathList.length - index);
            } else {
                leftTable.pathList.push({ id: id, name: name });
            }
            leftTable.parentId = id;
            return { leftTable: leftTable }
        }, () => {
            that.clearKeyword(() => {
                that.getUnSelectList(1, that.state.leftTable.relation.pageSize);
            });

        })
    }

    // 清除搜索条件
    clearKeyword = (callback?: () => void) => {
        this.setState((preState: any) => {
            var leftTable = preState.leftTable;
            //var rightTable = preState.rightTable;
            leftTable.keyword = '';
            //rightTable.keyword = '';
            return {
                leftTable: leftTable,
                //rightTable: rightTable
            }
        }, callback);
    }

    // 清除记忆
    clearData = (isClose: boolean = false) => {
        this.setState((preState: any) => {
            var leftTable = preState.leftTable;
            var rightTable = preState.rightTable;
            if (isClose) {
                leftTable.parentId = '';
                leftTable.pathList = [];
            }
            leftTable.checked = [];
            leftTable.keyword = '';
            rightTable.checked = [];
            rightTable.keyword = '';
            return {
                leftTable: leftTable,
                rightTable: rightTable
            }
        });
    }

    //关闭层
    onClose = () => {
        this.clearData(true);
        this.props.onClose && this.props.onClose();
    }



    render() {
        let { isOpen } = this.props;
        let { leftTable, rightTable } = this.state;
        const leftSource = leftTable.relation.list.map((item: any, idx: number) => {
            const isChecked = leftTable.checked.indexOf(idx) > -1;
            return { checked: isChecked, ...item };
        });
        const rightSource = rightTable.relation.list.map((item: any, idx: number) => {
            const isChecked = rightTable.checked.indexOf(idx) > -1;
            return { checked: isChecked, ...item };
        });
        return (
            <Shadow
                title={"选择需要同步的商品"}
                width={900}
                isOpen={isOpen}
                onClose={this.onClose.bind(this)}
                className="uni-half-pop"
                footer={
                    <Button type="primary" onClick={this.onClose.bind(this)}>确定</Button>
                }
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <div className="unit-half-item">
                            <div className="mt box box-center">
                                <p className="title box1">待选择</p>
                                <div className="custom-search">
                                    <Input placeholder="请输入商品名称" value={leftTable.keyword} onChange={this.onSearchLeft} />
                                    <Button type="primary" onClick={() => this.getUnSelectList(1, leftTable.relation.pageSize)}>搜索</Button>
                                </div>
                            </div>
                            <div className="mt box box-center">
                                {leftTable.pathList.map((i: any) => <a onClick={() => { this.onShowChild(i.id, i.name) }}>{i.name}/</a>)}
                            </div>
                            <div className="mc">
                                <Table className="custom-table" columns={leftTable.columns} data={leftSource} />
                            </div>
                            <div className="mf">
                                <Pagination alignment="right"
                                    current={leftTable.relation.pageIndex}
                                    pageSize={leftTable.relation.pageSize}
                                    total={leftTable.relation.count}
                                    divider
                                    onChange={(current: number, total: number, pageSize: number) => {
                                        this.getUnSelectList(current, pageSize);
                                    }} />
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="unit-half-item">
                            <div className="mt box box-center">
                                <p className="title box1">已选择</p>
                                <div className="custom-search">
                                    <Input placeholder="请输入商品名称" value={rightTable.keyword} onChange={this.onSearchRight} />
                                    <Button type="primary" onClick={() => this.getSelectList(1, rightTable.relation.pageSize)}>搜索</Button>
                                </div>
                            </div>
                            <div className="mc">
                                <Table className="custom-table" columns={rightTable.columns} data={rightSource} />
                            </div>
                            <div className="mf">
                                <Pagination alignment="right"
                                    current={rightTable.relation.pageIndex}
                                    pageSize={rightTable.relation.pageSize}
                                    total={rightTable.relation.count}
                                    divider
                                    onChange={(current: number, total: number, pageSize: number) => {
                                        this.getSelectList(current, pageSize);
                                    }} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Shadow>
        )
    }
}