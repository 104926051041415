import * as React from 'react';
import { Button, Message, Select, Radio, RadioGroup } from 'uiw';
import './index.scss';
import successImg from '@/assets/images/success.png'
import API from '@/utils/http';
import { Tab, FormItem, ClickInput, Shadow, Alert } from '@/components';
import { CustomerShadow, GoodsShadow, WareHouseShadow, UserShadow, StypeShadow, DepartmentShadow } from '@/widget/shadow';

export default class GuideHYSW extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            finished: false,
            stepIndex: 0,
            stepStr: ['价格同步设置', '库存同步设置', '订单信息设置'],
            stepProp: ['PriceSyncSetting', 'StorageStockSyncSetting', 'OrderInfoSetting'],
            stepModel: {

                "PriceSyncSetting": {
                    "SalePriceSetting": {
                        "Value": "0",
                        "List": []
                    },
                    "MktPriceSetting": {
                        "Value": "0",
                        "List": []
                    },
                    "CostPriceSetting": {
                        "Value": "0",
                        "List": []
                    }
                },
                "StorageStockSyncSetting": {

                    "StockSyncSetting": {
                        "Value": "0",
                        "List": [
                            {
                                "Key": "0",
                                "Value": "不同步"
                            },
                            {
                                "Key": "1",
                                "Value": "同步"
                            }
                        ]
                    },
                },

                "OrderInfoSetting": {
                    "OrderTypeSetting": {
                        "Value": "SalesSlip",
                        "List": [
                            {
                                "Key": "SalesSlip",
                                "Value": "订单草稿"
                            },
                            {
                                "Key": "SalesWarehousingReceipt",
                                "Value": "出库单草稿"
                            }
                        ]
                    },
                    "Btype": {
                        "Brec": 0,
                        "BUserCode": "",
                        "BFullName": "",
                        "Soncount": 0,
                        "ParID": "",
                        "BtypeID": "",
                        "BtypeID2": "",
                        "BtypeID3": null,
                        "EtypeID": null,
                        "EFullName": null,
                        "DtypeID": null,
                        "STypeId": null,
                        "KtypeId": null,
                        "IsHasChild": false,
                        "EcShopPriceId": null
                    },
                    
                }
            },
            stypeShadow: false,
            customerShadow: false,
            warehouseShadow: false,
            dtypeShadow: false,
            userShadow: false,
            rule: [
                '商城【客户编号】对应ERP【往来单位编码】',
                '商城【客户名称】对应ERP【往来单位名称】',
                '商城【联系电话】对应ERP【客户地址电话】',
                '商城【登录账号】对应ERP【客户地址电话】，若ERP“客户地址电话”值为空则对应上传【往来单位编码】'
            ],
            customerTipShadow: false,
            customerHalfShadow: false,
            goodsTipShadow: false,
            goodsHalfShadow: false,
        }
    }

    componentDidMount() {
        this.getGuideList();
    }


    getGuideList = () => {
        let that = this;
        let { stepProp, stepIndex } = that.state;
        let param = {
            mth: "GetGuideList"
        }
        API.postData(param, "/UI/Handler/GuideComplete.ashx", (res: any) => {
            console.log(res.data);
            let stepModel = res.data;
            that.setState({ stepModel: stepModel });
            while (!stepModel[stepProp[stepIndex]]) {
                that.nextStep();
                stepIndex++;
            }
        })
    }

    //改变选择框
    changeChooseSec = (q: any, select: any) => {
        let { stepModel } = this.state;
        stepModel[q].Value = select;
        this.setState({ stepModel: stepModel }, () => {
            // 商品同步
            if (q == 'ProductSyncSetting' && select != "0") {
                this.setState({
                    goodsTipShadow: true
                })
            }
            // 往来单位同步
            if (q === 'CustomerSyncSetting' && select != "0") {
                this.setState({
                    customerTipShadow: true
                })
            }
        });
    }

    // 选择框
    chooseSec = (q: any, model: any) => {
        let list = model.List;
        return (
            <ul className="choose-list">
                {list.map((item: any, index: number) =>
                    <li key={item.Key} className={item.Key === model.Value ? 'active' : ''} onClick={this.changeChooseSec.bind(this, q, item.Key)}>
                        <p>{item.Value}</p>
                    </li>
                )}
            </ul>
        )
    }

    // 改变下拉框
    changeSelectSec = (prop1: string, prop2: string, e: any) => {
        let { stepModel } = this.state;
        stepModel[prop1][prop2].Value = e.target.value;
        this.setState({ stepModel: stepModel });
    }

    // 下拉框
    selectSec = (list: Array<any>, active: any, prop1: string, prop2: string) => {
        let selected = list.find((i: any) => i.Key == active);

        return (
            <Select defaultValue={selected?.Key ?? active} onChange={this.changeSelectSec.bind(this, prop1, prop2)}>
                {!selected && <Select.Option key="" value="">请选择</Select.Option>}
                {list.map((item: any, index: number) =>
                    <Select.Option key={item.Key} value={item.Key}>{item.Value}</Select.Option>
                )}
            </Select>
        )
    }

    // 单选框
    changeSelect = (prop1: string, prop2: string, val: any) => {
        let { stepModel } = this.state;
        if (prop1 && prop2) {
            stepModel[prop1][prop2].Value = val;
            this.setState({ stepModel: stepModel });
        }
    }

    // 单选
    changeRadio = (prop1: string, prop2: string, e: any) => {
        let val = e.target.value;
        let { stepModel } = this.state;
        if (prop1 && prop2) {
            stepModel[prop1][prop2].Value = val;
            this.setState({ stepModel: stepModel });
        }
    }

    //下一步
    nextStep = () => {
        let { stepIndex, stepProp, stepModel } = this.state;
        if (stepIndex === 2) {
            this.submit();
        } else {
            let model = null;
            do {
                stepIndex++;
                if (stepIndex > 2) {
                    stepIndex = 0;
                }
                model = stepModel[stepProp[stepIndex]]
            } while (!model)
            this.setState({ stepIndex: stepIndex });
        }
    }

    //上一步
    prevStep = () => {
        let { stepIndex, stepProp, stepModel } = this.state;
        let model = null;
        do {
            stepIndex--;
            if (stepIndex < 0) {
                stepIndex = 2;
            }
            model = stepModel[stepProp[stepIndex]]
        } while (!model)
        this.setState({ stepIndex: stepIndex });
    }

    // 保存
    submit = () => {
        let { stepModel } = this.state;

        if (!this.verification(stepModel))
            return;

        let param = {
            mth: "SaveConfigGuide",
            model: JSON.stringify(stepModel)
        };
        API.postData(param, "/UI/Handler/GuideComplete.ashx", (res: any) => {
            if (res.code) {
                Alert(res.msg);
                return;
            }
            this.setState({ finished: true });
            this.updateImport();
        })
    }

    // 验证
    verification = (stepModel: any) => {
        if (stepModel.PriceSyncSetting.SalePriceSetting && !stepModel.PriceSyncSetting.SalePriceSetting.Value) {
            Alert("请选择市场价");
            return false;
        }
        if (stepModel.PriceSyncSetting.MktPriceSetting && !stepModel.PriceSyncSetting.MktPriceSetting.Value) {
            Alert("请选择销售价");
            return false;
        }
        if (stepModel.PriceSyncSetting.CostPriceSetting && !stepModel.PriceSyncSetting.CostPriceSetting.Value) {
            Alert("请选择成本价");
            return false;
        }
        if (!stepModel.OrderInfoSetting.OrderTypeSetting.Value) {
            Alert("请选择同步订单类型");
            return false;
        }
        if (!stepModel.OrderInfoSetting.Btype || !stepModel.OrderInfoSetting.Btype.BFullName) {
            Alert("往来单位为空");
            return false;
        }
        // if (!stepModel.OrderInfoSetting.Etype || !stepModel.OrderInfoSetting.Etype.EFullName) {
        //     Alert("职员为空");
        //     return false;
        // }
        // if (!stepModel.OrderInfoSetting.Ktype || !stepModel.OrderInfoSetting.Ktype.KFullName) {
        //     Alert("仓库为空");
        //     return false;
        // }
        // if (!stepModel.OrderInfoSetting.Dtype || !stepModel.OrderInfoSetting.Dtype.DFullName) {
        //     Alert("部门为空");
        //     return false;
        // }
        return true;
    }

    // 更新完成引导标识
    updateImport = () => {
        let param = {
            mth: "SaveGuideCompleteState",
            state: 1
        }
        API.postData(param, "/UI/Handler/SetUp.ashx", () => { });
    }

    //完成
    finished = () => {
        this.props.finished && this.props.finished();
    }

    render() {
        let { finished, stepIndex, stepStr, stepProp, stepModel, customerShadow, warehouseShadow, dtypeShadow, userShadow, stypeShadow,
            customerTipShadow, customerHalfShadow, goodsTipShadow, goodsHalfShadow, rule } = this.state;
        return (
            <div className="guide-system">
                {finished ?
                    <div className="success">
                        <img src={successImg} alt="" />
                        <p className="txt">全程通设置完成，数据正在同步，您可以点击下一步进入全程通主页面</p>
                        <div className="btn-group">
                            <Button type="primary" size="large" onClick={this.finished.bind(this)}>完成</Button>
                        </div>
                    </div>
                    :
                    <div className="step-cont box box-center">
                        <div className="step box box-vertical">
                            <div className="inner">
                                <p className="title">基础设置</p>
                                <ul>
                                    {
                                        stepStr.map((item: any, index: number) => {
                                            return (
                                                stepModel[stepProp[index]] && <li key={index} className={index === stepIndex ? 'active' : ''}>{index + 1}、{item}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="box1 cont box box-vertical">

                            {stepIndex === 0 && stepModel[stepProp[0]] &&
                                <div className="form">
                                    {stepModel[stepProp[0]].MktPriceSetting && <div className="form-item">
                                        <p className="title">请问您是需要将惠友商务的哪一个价格同步到商城作为市场价？</p>
                                        <Message type="warning" title="市场价是未注册用户/访客看到的价格" />
                                        {this.selectSec(stepModel[stepProp[0]].MktPriceSetting.List, stepModel[stepProp[0]].MktPriceSetting.Value, stepProp[0], "MktPriceSetting")}
                                    </div>}
                                    {stepModel[stepProp[0]].SalePriceSetting && <div className="form-item">
                                        <p className="title">请问您是需要将惠友商务的哪一个价格同步到商城作为销售价？</p>
                                        <Message type="warning" title="销售价是新注册用户看到的价格" />
                                        {this.selectSec(stepModel[stepProp[0]].SalePriceSetting.List, stepModel[stepProp[0]].SalePriceSetting.Value, stepProp[0], "SalePriceSetting")}
                                    </div>}
                                </div>
                            }
                            {stepIndex === 1 && stepModel[stepProp[1]] &&
                                <div className="form">

                                    <p className="title">请问您是需要同步库存？</p>
                                    <ul className="choose-list choose-list-sm">
                                        {stepModel[stepProp[1]].StockSyncSetting.List.map((item: any, index: number) =>
                                            <li key={item.Key} className={item.Key === stepModel[stepProp[1]].StockSyncSetting.Value ? 'active' : ''} onClick={this.changeSelect.bind(this, stepProp[1], "StockSyncSetting", item.Key)}>
                                                <p>{item.Value}</p>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            }

                            {stepIndex === 2 && stepModel[stepProp[2]] &&
                                <div className="form box1">
                                    <div className="form-item">
                                        <p className="title">将线上订单同步为以下单据</p>
                                        <div className="box box-center">
                                            <RadioGroup name="OrderTypeSetting" value={stepModel[stepProp[2]].OrderTypeSetting.Value} onChange={this.changeRadio.bind(this, stepProp[2], "OrderTypeSetting")}>
                                                {stepModel[stepProp[2]].OrderTypeSetting.List.map((item: any, index: number) =>
                                                    <Radio value={item.Key}>{item.Value}</Radio>
                                                )}
                                            </RadioGroup>
                                        </div>
                                    </div>
                                    <div className="form-item">
                                        <p className="title">当订单同步到惠友商务找不到对应的客商资料时，我们需要一个备用的客商信息，请选择备用客商：</p>
                                        <ClickInput className="w-sm" value={stepModel[stepProp[2]].Btype?.BFullName ?? ""} onClick={() => this.setState({ customerShadow: true })} />
                                    </div>
                                    {/* <div className="form-item">
                                        <p className="title">请选择惠友商务经常对订单进行过账处理的职员：</p>
                                        <ClickInput className="w-sm" value={stepModel[stepProp[2]].Etype?.EFullName ?? ""} onClick={() => this.setState({ userShadow: true })} />
                                    </div> */}
                                    {/* <div className="form-item">
                                        <p className="title">请选择惠友商务使用的主仓库：</p>
                                        <ClickInput className="w-sm" value={stepModel[stepProp[2]].Ktype?.KFullName ?? ""} onClick={() => this.setState({ warehouseShadow: true })} />
                                    </div> */}
                                </div>
                            }
                            <div className="btn-group">
                                {stepIndex !== 0 && <Button type="warning" onClick={this.prevStep.bind(this)}>上一步</Button>}
                                <Button type="primary" onClick={this.nextStep.bind(this)}>下一步</Button>
                            </div>
                        </div>
                    </div>
                }
                {/* 往来单位弹层 */}
                <CustomerShadow
                    isOpen={customerShadow}
                    onClose={() => this.setState({ customerShadow: false })}
                    onConfirm={(choosed: any) => {
                        stepModel.OrderInfoSetting.Btype = choosed;
                        this.setState({ stepModel: stepModel, customerShadow: false });
                    }}
                />

            </div>
        )
    }
}